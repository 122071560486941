import { jsx } from "react/jsx-runtime";
import React, { useMemo, useRef } from "react";
const FormErrorContext = React.createContext({
  value: "",
  setError: () => {
  }
});
const FormErrorReporter = ({ value, setError, children }) => {
  const errors = useRef({});
  const providerValue = useMemo(() => ({
    value,
    setError: (source, error) => {
      errors.current[source] = error;
      setError == null ? void 0 : setError(value, Object.values(errors.current).some((error2) => error2));
    }
  }), [value]);
  return /* @__PURE__ */ jsx(FormErrorContext.Provider, { value: providerValue, children });
};
var FormErrorReporter_default = FormErrorReporter;
export {
  FormErrorContext,
  FormErrorReporter,
  FormErrorReporter_default as default
};
