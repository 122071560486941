import { jsx } from "react/jsx-runtime";
import { browserIsIE } from "../../../utils";
import { useEffect } from "react";
import { Typography } from "@barracuda-internal/bds-core";
import { useHistory } from "react-router-dom";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { useTranslation } from "react-i18next";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  unsupported: {
    fontSize: 32
  }
});
const useStyles = makeOverrideableStyles("UnsupportedPage", styles);
const UnsupportedPage = (props) => {
  const unsupportedBrowser = browserIsIE();
  const [translate] = useTranslation();
  const { push } = useHistory();
  const classes = useStyles(props);
  useEffect(() => {
    !unsupportedBrowser && push("/");
  }, [unsupportedBrowser]);
  return unsupportedBrowser ? /* @__PURE__ */ jsx("div", { className: classes.root, id: "unsupported-page", children: /* @__PURE__ */ jsx(Typography, { variant: "h1", className: classes.unsupported, children: translate("cuda.unsupported.browser") }) }) : null;
};
var UnsupportedPage_default = UnsupportedPage;
export {
  UnsupportedPage,
  UnsupportedPage_default as default
};
