import { updateGlobalParams } from "../actions";
import { get, isEqual, set } from "lodash";
import { useDispatch, useSelector } from "react-redux";
const useGlobalParam = (source) => {
  const value = useSelector((state) => get(state, "GlobalParamsReducer" + (source ? "." + source : "")), isEqual);
  const dispatch = useDispatch();
  const updater = (value2) => dispatch(updateGlobalParams(source ? set({}, source, value2) : value2));
  return [value, updater];
};
export {
  useGlobalParam
};
