import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { FormControl } from "@mui/material";
import { Select, SelectMenuItem, Typography } from "@barracuda-internal/bds-core";
import FirstPageIcon from "@barracuda-internal/bds-core/dist/Icons/Core/PageFirst";
import LastPageIcon from "@barracuda-internal/bds-core/dist/Icons/Core/PageLast";
import NextPageIcon from "@barracuda-internal/bds-core/dist/Icons/Core/PageNext";
import PrevPageIcon from "@barracuda-internal/bds-core/dist/Icons/Core/PagePrevious";
import PagerIcon from "./PagerIcon";
import pagerLogic from "./PagerLogic";
import styles from "./pagerStyles";
const TEXTS = {
  itemsPerPage: "Items per page",
  gotoFirstPage: "Go to the first page",
  gotoPrevPage: "Go to the previous page",
  gotoNextPage: "Go to the next page",
  gotoLastPage: "Go to the last page"
};
const Pager = (props) => {
  var _a, _b, _c, _d, _e;
  const classes = styles();
  return /* @__PURE__ */ jsxs("div", { className: "k-pager-wrap k-floatwrap k-widget k-grid-pager", children: [
    /* @__PURE__ */ jsx(
      PagerIcon,
      {
        config: props.buttons.firstPage,
        title: ((_a = props.i18nTexts) == null ? void 0 : _a.gotoFirstPage) || TEXTS.gotoFirstPage,
        Icon: FirstPageIcon
      }
    ),
    /* @__PURE__ */ jsx(
      PagerIcon,
      {
        config: props.buttons.prevPage,
        title: ((_b = props.i18nTexts) == null ? void 0 : _b.gotoPrevPage) || TEXTS.gotoPrevPage,
        Icon: PrevPageIcon
      }
    ),
    props.dotsStatuses.isPrevVisible && /* @__PURE__ */ jsx("strong", { className: classes.dots, children: "..." }),
    /* @__PURE__ */ jsx("ul", { className: "k-pager-numbers k-reset", children: /* @__PURE__ */ jsx("li", { children: props.pages.map((pageConfig) => /* @__PURE__ */ jsx(
      "button",
      {
        onClick: pageConfig.onClick,
        className: `k-link ${pageConfig.isActive ? "k-state-selected" : ""}`,
        children: pageConfig.page
      },
      pageConfig.page
    )) }) }),
    props.dotsStatuses.isNextVisible && /* @__PURE__ */ jsx("strong", { className: classes.dots, children: "..." }),
    /* @__PURE__ */ jsx(
      PagerIcon,
      {
        config: props.buttons.nextPage,
        title: ((_c = props.i18nTexts) == null ? void 0 : _c.gotoNextPage) || TEXTS.gotoNextPage,
        Icon: NextPageIcon
      }
    ),
    /* @__PURE__ */ jsx(
      PagerIcon,
      {
        config: props.buttons.lastPage,
        title: ((_d = props.i18nTexts) == null ? void 0 : _d.gotoLastPage) || TEXTS.gotoLastPage,
        Icon: LastPageIcon
      }
    ),
    props.itemsPerPageConfig && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsxs("span", { className: `k-pager-sizes k-label ${classes.itemsPerPage}`, children: [
        ((_e = props.i18nTexts) == null ? void 0 : _e.itemsPerPage) || TEXTS.itemsPerPage,
        "\xA0\xA0"
      ] }),
      /* @__PURE__ */ jsx(FormControl, { variant: "outlined", size: "small", children: /* @__PURE__ */ jsx(
        Select,
        {
          onChange: props.itemsPerPageConfig.onChange,
          value: props.itemsPerPageConfig.value,
          renderValue: () => /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(Typography, { children: (props.itemsPerPageConfig || {}).value }) }),
          children: props.itemsPerPageConfig.menuItems.map((menuItem) => /* @__PURE__ */ jsx(SelectMenuItem, { value: menuItem.id, children: `${menuItem.value}` }, menuItem.id))
        }
      ) })
    ] }),
    props.info && /* @__PURE__ */ jsx("div", { className: "k-pager-info k-label", children: `${props.from}-${props.to} of ${props.total}` })
  ] });
};
var Pager_default = pagerLogic(Pager);
export {
  Pager,
  Pager_default as default
};
