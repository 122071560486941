import { format, isValid, parseISO } from "date-fns";
const formatDateTime = (dateText, formatString = "yyyy-MM-dd HH:mm") => {
  if (dateText) {
    const date = parseISO(dateText);
    if (isValid(date)) {
      return format(date, formatString);
    }
  }
  return "";
};
const formatDate = (dateText) => dateText ? formatDateTime(dateText, "yyyy-MM-dd") : "";
const formatNumber = (num) => num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "";
const removeStyleTags = (inputString) => inputString ? inputString.replace(/<style([\s\S]+?)<\/style>/g, "") : "";
export {
  formatDate,
  formatDateTime,
  formatNumber,
  removeStyleTags
};
