var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { TextField } from "@barracuda-internal/bds-core";
import { formatErrorMessage } from "../../../utils";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { useTranslation } from "react-i18next";
import { createStyles } from "@mui/styles";
import { get } from "lodash";
const styles = (theme) => createStyles({
  textField: {
    width: (props) => get(props, "options.fullWidth") ? "100%" : 256,
    marginTop: theme.spacing(0.5)
  }
});
const useStyles = makeOverrideableStyles("Text", styles);
const Text = (props) => {
  var _a;
  const {
    id,
    type,
    options,
    onBlur,
    onFocus,
    onChange,
    value,
    error,
    disabled,
    placeholder,
    label
  } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsx(
    TextField,
    __spreadProps(__spreadValues({
      value: value || "",
      onBlur: (eventOrValue) => {
        onBlur && onBlur(eventOrValue);
      },
      onFocus: (event) => {
        onFocus && onFocus(event);
      },
      onChange: (eventOrValue) => {
        onChange && onChange(eventOrValue);
      },
      type: type === "integer" || type === "number" ? "text" : type,
      helperText: formatErrorMessage(error),
      error: !!error,
      placeholder: placeholder && translate(placeholder),
      label: label && translate(label)
    }, options), {
      InputProps: __spreadProps(__spreadValues({}, (options == null ? void 0 : options.InputProps) || {}), {
        inputProps: __spreadProps(__spreadValues({}, ((_a = options == null ? void 0 : options.InputProps) == null ? void 0 : _a.inputProps) || {}), {
          onKeyDown: (event) => {
            if (type === "integer" || type === "number") {
              const regExp = type === "integer" ? /[^0-9-]/g : /[^0-9-.]/g;
              if (regExp.test(event.key) && !(event.key === "Backspace") && !(event.key === "Enter") && !(event.key === "Tab")) {
                event.preventDefault();
              }
            }
          }
        })
      }),
      disabled,
      id: id && "text-input-" + id,
      className: classes.textField,
      variant: "outlined"
    })
  );
};
Text.defaultProps = {
  onBlur: () => {
  },
  onChange: () => {
  },
  onFocus: () => {
  },
  options: {},
  type: "text"
};
var Text_default = Text;
export {
  Text,
  Text_default as default,
  styles
};
