var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { merge } from "lodash";
const SET_GLOBAL_PARAMS = "CUDA/SET_GLOBAL_PARAMS";
const UPDATE_GLOBAL_PARAMS = "CUDA/UPDATE_GLOBAL_PARAM";
const setGlobalParams = (params) => ({
  type: SET_GLOBAL_PARAMS,
  params
});
const updateGlobalParams = (params) => ({
  type: UPDATE_GLOBAL_PARAMS,
  params
});
const enhanceActionWithGlobalParams = (action) => (dispatch, getState) => {
  const state = getState();
  const globalParams = state && state.GlobalParamsReducer || {};
  const authParams = state && state.AuthReducer || {};
  dispatch(__spreadProps(__spreadValues({}, action), { globalParams: merge({}, authParams, globalParams) }));
};
export {
  SET_GLOBAL_PARAMS,
  UPDATE_GLOBAL_PARAMS,
  enhanceActionWithGlobalParams,
  setGlobalParams,
  updateGlobalParams
};
