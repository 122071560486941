import { jsx } from "react/jsx-runtime";
import React, { Children } from "react";
import { TabPanel } from "@barracuda-internal/bds-core";
import classNames from "classnames";
import ErrorBoundary from "../../functional/ErrorBoundary/ErrorBoundary";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = createStyles({
  tabPanel: {
    padding: 0,
    "& > div": {
      padding: 0
    }
  },
  tabHidden: {
    display: "none"
  }
});
const useStyles = makeOverrideableStyles("Tab", styles);
const Tab = (props) => {
  const { children, value, activeValue, alwaysRender, setDisplayNone } = props;
  const classes = useStyles(props);
  const childArray = Children.toArray(children).filter((child) => !!child);
  return /* @__PURE__ */ jsx(TabPanel, { className: classNames(classes.tabPanel, value !== activeValue && setDisplayNone && classes.tabHidden), children: value === activeValue || alwaysRender ? childArray.map((child, index) => /* @__PURE__ */ jsx(ErrorBoundary, { hide: activeValue !== value, children: React.cloneElement(child, { visible: activeValue === value }) }, index)) : null });
};
var Tab_default = React.memo(Tab);
export {
  Tab,
  Tab_default as default
};
