import { jsx } from "react/jsx-runtime";
import PropTypes from "prop-types";
import styles from "./pagerStyles";
const PagerIcon = ({ config, title, Icon }) => {
  const classes = styles();
  return /* @__PURE__ */ jsx(
    "button",
    {
      className: `k-link k-pager-nav k-pager-first ${config.isDisabled ? `k-state-disabled ${classes.disabledIcon}` : ""}`,
      onClick: config.onClick,
      children: /* @__PURE__ */ jsx(Icon, { className: `k-icon ${classes.icon}`, alt: "", "aria-label": title })
    }
  );
};
PagerIcon.propTypes = {
  config: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  Icon: PropTypes.any.isRequired
};
var PagerIcon_default = PagerIcon;
export {
  PagerIcon_default as default
};
