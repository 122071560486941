import { jsx, jsxs } from "react/jsx-runtime";
import { CircularProgress, Collapse, IconButton, Paper, Typography } from "@barracuda-internal/bds-core";
import classNames from "classnames";
import { Close } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  defaults: {
    backgroundColor: theme.palette.primary.main
  },
  banner: {
    width: "100%",
    textAlign: "center",
    color: theme.palette.common.white,
    padding: "14px 30px 14px 30px",
    borderRadius: 0
  },
  typography: {
    verticalAlign: "middle",
    color: theme.palette.common.white,
    // @ts-ignore
    fontSize: theme.typography.htmlFontSize,
    display: "inline"
  },
  closeButton: {
    float: "right",
    padding: 0
  },
  closeIcon: {
    color: theme.palette.common.white
  },
  spinner: {
    marginRight: 15,
    verticalAlign: "middle",
    color: theme.palette.common.white
  }
});
const useStyles = makeOverrideableStyles("StatusBanner", styles);
const StatusBanner = (props) => {
  const { dismissible, spinner, open, onClose, children } = props;
  const classes = useStyles(props);
  return /* @__PURE__ */ jsx(Collapse, { in: open, unmountOnExit: true, mountOnEnter: true, children: /* @__PURE__ */ jsxs(Paper, { className: classNames(classes.banner, classes.defaults), children: [
    spinner && /* @__PURE__ */ jsx(CircularProgress, { size: 20, className: classes.spinner }),
    /* @__PURE__ */ jsx(Typography, { className: classes.typography, children }),
    dismissible && onClose && /* @__PURE__ */ jsx(
      IconButton,
      {
        className: classes.closeButton,
        onClick: onClose,
        size: "small",
        children: /* @__PURE__ */ jsx(Close, { id: "cuda-icon-close", className: classes.closeIcon })
      }
    )
  ] }) });
};
StatusBanner.defaultProps = {
  dismissible: true,
  open: true
};
var StatusBanner_default = StatusBanner;
export {
  StatusBanner,
  StatusBanner_default as default
};
