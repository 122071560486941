import { jsx, jsxs } from "react/jsx-runtime";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { Typography } from "@barracuda-internal/bds-core";
import { Close } from "@barracuda-internal/bds-core/dist/Icons/Core";
import React from "react";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { useTranslation } from "react-i18next";
import ErrorBoundary from "../../functional/ErrorBoundary/ErrorBoundary";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  title: {
    padding: theme.spacing(1, 0),
    margin: theme.spacing(0, 2),
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    borderWidth: "0 0 1px"
  },
  titleText: {
    fontWeight: 400,
    fontSize: 20
  },
  closeIcon: {
    float: "right"
  },
  dialogContent: {
    padding: 0,
    width: (props) => props.form && 680 || void 0,
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    overflowX: "hidden"
  }
});
const useStyles = makeOverrideableStyles("DialogBody", styles);
const DialogBody = (props) => {
  const { children, onClose, title } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsxs(DialogTitle, { className: classes.title, children: [
      title && /* @__PURE__ */ jsx(
        Typography,
        {
          className: classes.titleText,
          component: "span",
          children: translate(title)
        }
      ),
      onClose && /* @__PURE__ */ jsx(
        IconButton,
        {
          size: "small",
          className: classes.closeIcon,
          onClick: onClose,
          "aria-label": "close",
          disableRipple: true,
          disableTouchRipple: true,
          children: /* @__PURE__ */ jsx(Close, { id: "cuda-icon-close" })
        }
      )
    ] }),
    /* @__PURE__ */ jsx(ErrorBoundary, { children: /* @__PURE__ */ jsx(DialogContent, { className: classes.dialogContent, children }) })
  ] });
};
var DialogBody_default = DialogBody;
export {
  DialogBody,
  DialogBody_default as default
};
