var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { IconButton, Typography } from "@barracuda-internal/bds-core";
import Tooltip from "@barracuda-internal/bds-core/dist/Tooltip";
import classNames from "classnames";
import { Info } from "@barracuda-internal/bds-core/dist/Icons/Feedback";
import { createStyles } from "@mui/styles";
import { Trans, useTranslation } from "react-i18next";
import { makeOverrideableStyles } from "@cuda-react/theme";
const getLabel = (translate, label, isRequired) => {
  let newLabel = translate(label || "");
  if (isRequired) {
    newLabel += " *";
  }
  return newLabel;
};
const styles = (theme) => createStyles({
  root: {
    textAlign: "left",
    padding: (props) => props.minimised ? 0 : props.nested ? theme.spacing(0, 8) : theme.spacing(0, 4),
    width: (props) => props.fullWidth && "100%" || (props.minimised || props.autoWidth ? "auto" : "calc(100% - 64px)"),
    display: "inline-block",
    minHeight: 40,
    margin: (props) => props.minimised ? 0 : theme.spacing(1.2, 0)
  },
  inputContainerVarHeight: {
    maxHeight: "inherit"
  },
  inputHelp: {
    display: "inline-block",
    float: "left",
    height: "auto",
    margin: "3px 12px 5px 0",
    width: (props) => props.minimised ? "auto" : 20
  },
  inputLabel: {
    display: "inline-block",
    float: "left",
    width: (props) => props.minimised ? "auto" : 210,
    padding: (props) => props.minimised ? theme.spacing(1.25, 2, 1.25, 0) : theme.spacing(1.25, 0),
    color: theme.palette.text.secondary,
    lineHeight: "19px"
  },
  newStyleInputLabel: {
    fontWeight: "bold"
  },
  inputAdditionalInfo: {
    maxWidth: "100%",
    fontSize: 12,
    lineHeight: "18px",
    marginTop: 4,
    marginLeft: (props) => props.minimised ? 0 : 245
  },
  infoIcon: {
    fill: theme.palette.text.secondary
  },
  tooltip: __spreadProps(__spreadValues({}, theme.typography.body2), {
    // @ts-ignore Added by Cuda-react. Default (undefined), is fine
    color: theme.palette.text.tooltip
  }),
  hideInput: {
    display: "none"
  }
});
const useStyles = makeOverrideableStyles("InputLabel", styles);
const InputLabel = (props) => {
  const {
    additionalInfoLabel,
    children,
    description,
    isRequired,
    isVarHeight,
    hideInput,
    label,
    newStyle
  } = props;
  const [translate] = useTranslation();
  const classes = useStyles(props);
  return /* @__PURE__ */ jsxs(
    "div",
    {
      className: classNames(
        classes.root,
        isVarHeight ? classes.inputContainerVarHeight : void 0,
        hideInput && classes.hideInput
      ),
      id: "cuda-react-input",
      children: [
        /* @__PURE__ */ jsx("div", { className: classes.inputHelp, children: description && /* @__PURE__ */ jsx(
          Tooltip,
          {
            title: typeof description === "string" ? translate(description) : description,
            placement: "top-end",
            classes: { tooltip: classes.tooltip },
            children: /* @__PURE__ */ jsx(IconButton, { size: "small", children: /* @__PURE__ */ jsx(Info, { className: classes.infoIcon }) })
          }
        ) }),
        /* @__PURE__ */ jsx(
          Typography,
          {
            variant: "body1",
            className: classNames(classes.inputLabel, newStyle ? classes.newStyleInputLabel : void 0),
            children: getLabel(translate, label, isRequired)
          }
        ),
        children,
        additionalInfoLabel ? /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.inputAdditionalInfo, children: typeof additionalInfoLabel === "string" ? /* @__PURE__ */ jsx(Trans, { i18nKey: additionalInfoLabel, children: /* @__PURE__ */ jsx("br", {}) }) : /* @__PURE__ */ jsx(Trans, { i18nKey: additionalInfoLabel.i18nKey, values: additionalInfoLabel.values, children: /* @__PURE__ */ jsx("br", {}) }) }) : null
      ]
    }
  );
};
var InputLabel_default = InputLabel;
export {
  InputLabel,
  InputLabel_default as default
};
