var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { IconButton, Menu, MenuItem, Tab as BdsTab, Tabs as BdsTabs, Typography } from "@barracuda-internal/bds-core";
import React, { Children, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { difference } from "lodash";
import classNames from "classnames";
import { createStyles } from "@mui/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useWidthTracking } from "../../../utils/useWidthTracking";
const styles = (theme) => createStyles({
  tabRow: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center"
  },
  tabs: {
    minWidth: (props) => props.visibleTabCount * 60,
    flexGrow: 1
  },
  tabsFlexContainer: {
    alignItems: "center"
  },
  tab: {
    width: "max-content",
    padding: theme.spacing(0, 2)
  },
  tabMenu: {
    // flexGrow: 1
  },
  actions: {
    display: "flex",
    flexShrink: 0
  },
  tabTitleError: {
    color: theme.palette.error.main
  },
  tabIcon: {
    minHeight: 32
  },
  tabStatus: {
    whiteSpace: "nowrap",
    minHeight: 32
  },
  tabStatusIcon: {
    "& > svg": {
      position: "relative",
      top: theme.spacing(0.5),
      marginRight: theme.spacing(1)
    }
  },
  tabStatusText: {
    display: "inline"
  }
});
const useStyles = makeOverrideableStyles("Tabs", styles);
const Tabs = (props) => {
  const { children, activeTab, onChange, actions, condensedMenu } = props;
  const [tabsRef, tabsWidth] = useWidthTracking(0);
  const [translate] = useTranslation();
  const childArray = Children.toArray(children).filter((child) => !!child).map((child, index) => child.props.value ? child : React.cloneElement(child, { value: index }));
  const [localSelectedTab, setLocalSelectedTab] = useState(childArray[0].props.value);
  const selectedTab = activeTab !== void 0 ? activeTab : localSelectedTab;
  const [anchorElementMenu, setAnchorElementMenu] = useState(null);
  const maxChildren = Math.floor((tabsWidth - 30) / (props.compact ? 60 : 160));
  const activeTabIndex = childArray.findIndex((child) => {
    var _a;
    return ((_a = child == null ? void 0 : child.props) == null ? void 0 : _a.value) === selectedTab;
  });
  const start = Math.max(0, Math.min(childArray.length - maxChildren, activeTabIndex - Math.floor(maxChildren / 2)));
  const displayedTabs = condensedMenu ? childArray.slice(start, start + maxChildren) : childArray;
  const notDisplayedTabs = difference(childArray, displayedTabs);
  const cropped = notDisplayedTabs.length > 0;
  const visibleTabCount = displayedTabs.length;
  const compact = props.compact || visibleTabCount * 160 > tabsWidth;
  const classes = useStyles(__spreadProps(__spreadValues({}, props), {
    compact,
    visibleTabCount
  }));
  const handleChange = (event, newValue) => {
    setLocalSelectedTab(newValue);
    onChange == null ? void 0 : onChange(newValue);
  };
  const tabsHaveStatus = childArray.some((tab) => tab.props.status);
  const tabsHaveIcons = childArray.some((tab) => tab.props.icon);
  return /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsxs("div", { className: classes.tabRow, children: [
      /* @__PURE__ */ jsxs(
        BdsTabs,
        {
          id: "cuda-react-tabs",
          indicatorColor: "primary",
          textColor: "primary",
          variant: "standard",
          value: selectedTab,
          onChange: handleChange,
          className: classes.tabs,
          classes: { flexContainer: classes.tabsFlexContainer },
          ref: tabsRef,
          children: [
            displayedTabs.map((tab) => {
              const label = /* @__PURE__ */ jsxs("span", { children: [
                tab.props.icon && tabsHaveStatus ? tab.props.icon : null,
                translate(tab.props.label)
              ] });
              const icon = tabsHaveStatus ? /* @__PURE__ */ jsxs("span", { style: { color: tab.props.statusColor }, className: classes.tabStatus, children: [
                tab.props.status && tab.props.statusIcon ? /* @__PURE__ */ jsx("span", { className: classes.tabStatusIcon, children: React.cloneElement(tab.props.statusIcon, tab.props.statusIconProps) }) : null,
                tab.props.status ? /* @__PURE__ */ jsx(Typography, { className: classes.tabStatusText, style: { color: tab.props.statusColor }, children: translate(tab.props.status) }) : null
              ] }) : tabsHaveIcons ? /* @__PURE__ */ jsx("span", { className: classes.tabIcon, children: tab.props.icon || null }) : null;
              return /* @__PURE__ */ jsx(
                BdsTab,
                {
                  wrapped: true,
                  className: classNames(
                    classes.tab,
                    tab.props.error && classes.tabTitleError
                  ),
                  icon: tabsHaveStatus ? label : icon,
                  value: tab.props.value,
                  label: tabsHaveStatus ? icon : label,
                  disabled: tab.props.disabled,
                  sx: {
                    minWidth: compact ? 60 : 160,
                    maxWidth: `${100 / visibleTabCount}%`
                  }
                },
                tab.props.value
              );
            }),
            cropped && condensedMenu ? /* @__PURE__ */ jsxs("div", { className: classes.tabMenu, children: [
              /* @__PURE__ */ jsx(
                IconButton,
                {
                  size: "small",
                  "aria-label": "more",
                  "aria-controls": "long-menu",
                  "aria-haspopup": "true",
                  onClick: (event) => {
                    setAnchorElementMenu(event.currentTarget);
                  },
                  className: classes.moreIconButton,
                  children: /* @__PURE__ */ jsx(MoreVertIcon, {})
                }
              ),
              /* @__PURE__ */ jsx(
                Menu,
                {
                  id: "long-menu",
                  anchorEl: anchorElementMenu,
                  keepMounted: true,
                  open: Boolean(anchorElementMenu),
                  onClose: () => {
                    setAnchorElementMenu(null);
                  },
                  children: notDisplayedTabs.map((child, index) => {
                    var _a;
                    return /* @__PURE__ */ jsx(
                      MenuItem,
                      {
                        onClick: () => {
                          var _a2;
                          handleChange({}, (_a2 = child == null ? void 0 : child.props) == null ? void 0 : _a2.value);
                          setAnchorElementMenu(null);
                        },
                        children: (_a = child == null ? void 0 : child.props) == null ? void 0 : _a.label
                      },
                      index
                    );
                  })
                }
              )
            ] }) : null
          ]
        }
      ),
      actions ? /* @__PURE__ */ jsx("div", { className: classes.actions, children: actions }) : null
    ] }),
    childArray.map((tab) => React.cloneElement(tab, {
      key: tab.props.value,
      activeValue: selectedTab
    }))
  ] });
};
var Tabs_default = React.memo(Tabs);
export {
  Tabs,
  Tabs_default as default,
  styles
};
