var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Card, CardContent } from "@barracuda-internal/bds-core";
import { Grid, GridNoRecords } from "@progress/kendo-react-grid";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { makeOverrideableStyles } from "@cuda-react/theme";
import classNames from "classnames";
import { createStyles } from "@mui/styles";
import Pager from "../Pager/Pager";
const styles = (theme) => createStyles({
  card: {
    width: "100%"
  },
  cardContent: {
    padding: 0,
    "&:last-child": {
      padding: 0
    },
    position: "relative"
  },
  tableFitToScreen: {
    "& .k-grid-header-wrap": {
      marginRight: 14
    },
    "& .k-grid-content": {
      overflowY: "scroll !important"
    },
    "& .k-grid-container": {
      borderRadius: theme.shape.borderRadius
    },
    "& .k-checkbox": {
      borderColor: theme.palette.primary.main,
      verticalAlign: "middle !important",
      width: "12px",
      height: "12px"
    },
    "& .k-checkbox:checked": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    }
  },
  flat: {
    marginTop: 0
  }
});
const useStyles = makeOverrideableStyles("Table", styles);
const MemoGrid = memo(Grid);
const Table = (props) => {
  const _a = props, { children, className, flat, noRecordsMessage, tableActions, fitToScreen } = _a, tableProps = __objRest(_a, ["children", "className", "flat", "noRecordsMessage", "tableActions", "fitToScreen"]);
  const [calcOffset, setCalcOffset] = useState(400);
  const classes = useStyles(__spreadValues({ calcOffset }, props));
  const contentRef = useRef(null);
  const tableChildren = useMemo(() => [
    children || null,
    noRecordsMessage ? /* @__PURE__ */ jsx(GridNoRecords, { children: noRecordsMessage }, "noRecords") : null
  ].flat(), [noRecordsMessage, children]);
  useEffect(() => {
    var _a2, _b;
    const currentOffset = (((_b = (_a2 = contentRef.current) == null ? void 0 : _a2.getBoundingClientRect) == null ? void 0 : _b.call(_a2)) || { top: calcOffset }).top;
    if (fitToScreen) {
      setCalcOffset(currentOffset);
    }
  }, [fitToScreen]);
  const tableContent = /* @__PURE__ */ jsx(
    CardContent,
    {
      className: classNames(classes.cardContent),
      ref: contentRef,
      children: /* @__PURE__ */ jsx(
        MemoGrid,
        __spreadProps(__spreadValues({
          pager: Pager,
          className: classNames(fitToScreen && classes.tableFitToScreen, className),
          style: { maxHeight: props.maxHeight ? props.maxHeight : fitToScreen ? `max(calc(100vh - ${calcOffset + 64}px), 256px)` : void 0 }
        }, tableProps), {
          children: tableChildren
        })
      )
    }
  );
  return flat ? /* @__PURE__ */ jsxs("div", { className: classes.flat, children: [
    tableActions,
    tableContent
  ] }) : /* @__PURE__ */ jsxs(React.Fragment, { children: [
    tableActions,
    /* @__PURE__ */ jsx(Card, { className: classes.card, elevation: 1, children: tableContent })
  ] });
};
var Table_default = Table;
export {
  Table_default as default
};
