var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import { Button, Typography } from "@barracuda-internal/bds-core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  BANNER_NOTIFICATION,
  DIALOG_NOTIFICATION,
  ERROR_NOTIFICATION,
  INFO_NOTIFICATION,
  notificationHide,
  OK_NOTIFICATION,
  TOAST_NOTIFICATION,
  WARNING_NOTIFICATION
} from "../../../actions";
import StatusBanner from "../../layout/StatusBanner/StatusBanner";
import Toolbar from "../../layout/Toolbar/Toolbar";
import DialogBody from "../DialogBody/DialogBody";
import { usePrevious } from "../../../hooks";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    colorInherit: theme.palette.common.white
  },
  title: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main
  },
  closeIcon: {
    color: theme.palette.common.white,
    float: "right"
  },
  dialogPaper: {
    minWidth: "fit-content"
  },
  dialogContent: {
    textAlign: "center",
    padding: 16
  },
  [INFO_NOTIFICATION]: {
    backgroundColor: theme.palette.primary.main
  },
  [ERROR_NOTIFICATION]: {
    backgroundColor: theme.palette.error.main
  },
  [WARNING_NOTIFICATION]: {
    backgroundColor: theme.palette.warning.main
  },
  [OK_NOTIFICATION]: {
    backgroundColor: theme.palette.success.main
  }
});
const useStyles = makeOverrideableStyles("NotificationDialog", styles);
const NotificationDialog = (props) => {
  var _a, _b;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const dispatch = useDispatch();
  const handleRequestClose = (type, content) => () => dispatch(notificationHide(type, content));
  const notifications = useSelector((state) => state.NotificationReducer);
  const toastContents = ((notifications == null ? void 0 : notifications[TOAST_NOTIFICATION]) || []).map((notification) => notification.content);
  const closedToastNotifications = (usePrevious(notifications == null ? void 0 : notifications[TOAST_NOTIFICATION]) || []).filter((notification) => !toastContents.includes(notification.content)).map((notification) => __spreadProps(__spreadValues({}, notification), { closed: true }));
  const dialogContentArray = Array.isArray((_a = notifications == null ? void 0 : notifications[DIALOG_NOTIFICATION]) == null ? void 0 : _a.content) ? notifications == null ? void 0 : notifications[DIALOG_NOTIFICATION].content : [(_b = notifications == null ? void 0 : notifications[DIALOG_NOTIFICATION]) == null ? void 0 : _b.content];
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    (notifications == null ? void 0 : notifications[BANNER_NOTIFICATION]) && (notifications == null ? void 0 : notifications[BANNER_NOTIFICATION].map((bannerNotification) => /* @__PURE__ */ jsx(
      StatusBanner,
      {
        open: true,
        onClose: handleRequestClose(BANNER_NOTIFICATION, bannerNotification.content),
        dismissible: bannerNotification.dismissible,
        classes: bannerNotification.level && { banner: classes[bannerNotification.level] },
        spinner: bannerNotification.spinner,
        children: /* @__PURE__ */ jsx(
          "span",
          {
            dangerouslySetInnerHTML: {
              __html: translate(
                bannerNotification.content || "",
                bannerNotification.translateParams
              )
            }
          }
        )
      },
      bannerNotification.content
    ))),
    (notifications == null ? void 0 : notifications[DIALOG_NOTIFICATION]) && /* @__PURE__ */ jsx(
      Dialog,
      {
        open: true,
        onClose: handleRequestClose(DIALOG_NOTIFICATION),
        className: classes.root,
        classes: { paper: classes.dialogPaper },
        children: /* @__PURE__ */ jsxs(
          DialogBody,
          {
            title: "cuda.notification.title",
            onClose: handleRequestClose(DIALOG_NOTIFICATION),
            children: [
              /* @__PURE__ */ jsx(DialogContent, { className: classes.dialogContent, children: dialogContentArray.map((content, index) => /* @__PURE__ */ jsx(Typography, { component: "p", children: translate(content, notifications == null ? void 0 : notifications[DIALOG_NOTIFICATION].translateParams) }, index)) }),
              /* @__PURE__ */ jsx(Toolbar, { children: /* @__PURE__ */ jsx(
                Button,
                {
                  onClick: handleRequestClose(DIALOG_NOTIFICATION),
                  color: "primary",
                  variant: "contained",
                  size: "small",
                  children: translate("cuda.notification.ok")
                }
              ) })
            ]
          }
        )
      }
    ),
    [...(notifications == null ? void 0 : notifications[TOAST_NOTIFICATION]) || [], ...closedToastNotifications].map((notification, index) => /* @__PURE__ */ jsx(
      Snackbar,
      {
        open: index === 0 && !notification.closed,
        onClose: notification && handleRequestClose(TOAST_NOTIFICATION, notification.content),
        autoHideDuration: notification && notification.duration,
        className: notification.level && classes[notification.level],
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        children: /* @__PURE__ */ jsx(
          SnackbarContent,
          {
            className: notification.level && classes[notification.level],
            message: notification.content && translate(notification.content, notification.translateParams)
          }
        )
      },
      notification && notification.content
    ))
  ] });
};
var NotificationDialog_default = NotificationDialog;
export {
  NotificationDialog,
  NotificationDialog_default as default
};
