import { useEffect, useRef, useState } from "react";
const useWidthTracking = (initialWidth = 1024) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(initialWidth);
  const checkWidth = () => {
    var _a;
    if (ref.current) {
      setWidth((_a = ref.current) == null ? void 0 : _a.offsetWidth);
    }
  };
  useEffect(() => {
    checkWidth();
    window.addEventListener("resize", checkWidth);
    return () => window.removeEventListener("resize", checkWidth);
  }, []);
  return [ref, width];
};
export {
  useWidthTracking
};
