var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { createContext, useCallback, useEffect, useRef } from "react";
import {
  FormProvider as ReactHookFormProvider,
  useForm as useReactHookForm
} from "react-hook-form";
import { cloneDeep, every, isEmpty } from "lodash";
import { useDeepCompareEffect } from "../../../hooks";
import { Prompt } from "react-router";
import { useTranslation } from "react-i18next";
const deepFlattenCallback = (callback, input, parent = void 0) => {
  Object.keys(input).forEach((key) => {
    let propName = parent ? parent + "." + key : key;
    if (typeof input[key] === "object") {
      deepFlattenCallback(callback, input[key], propName);
    } else {
      input[key] && callback(propName, input[key]);
    }
  });
};
const isDeepEmpty = (value) => typeof value === "object" ? every(value, isDeepEmpty) : isEmpty(value);
const FormPropsContext = createContext({});
const FormProvider = (_a) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  const {
    clearOnSuccess,
    initialErrors,
    initialValues = {},
    onChange,
    onSubmitSuccess,
    save,
    validate,
    hookFormProps,
    allowDirtyNavigation
  } = props;
  const methods = useReactHookForm(__spreadValues({ defaultValues: cloneDeep(initialValues) }, hookFormProps));
  const {
    reset,
    setError,
    clearErrors,
    formState: { submitCount, isSubmitSuccessful, isSubmitted, dirtyFields },
    handleSubmit,
    trigger,
    watch
  } = methods;
  const [translate] = useTranslation();
  const watchExtraData = useRef({ initialValues, props });
  watchExtraData.current.initialValues = initialValues;
  watchExtraData.current.props = props;
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;
  const handleErrors = (response) => {
    deepFlattenCallback((key, message) => {
      setError(key, { message });
    }, response);
  };
  const expandedWatch = useCallback((callback, initialize = true) => {
    initialize && callback(methods.getValues(), watchExtraData.current.initialValues, watchExtraData.current.props);
    return watch((formData) => callback(formData, watchExtraData.current.initialValues, watchExtraData.current.props));
  }, []);
  useEffect(() => {
    var _a2;
    (_a2 = onChangeRef.current) == null ? void 0 : _a2.call(onChangeRef, methods.getValues(), watchExtraData.current.initialValues);
  }, []);
  useDeepCompareEffect(() => {
    const subscription = expandedWatch((formValues, initialValues2) => {
      var _a2;
      (_a2 = onChangeRef.current) == null ? void 0 : _a2.call(onChangeRef, formValues, initialValues2);
      if (isSubmitted && !isSubmitSuccessful) {
        trigger();
      }
    }, false);
    return () => subscription.unsubscribe();
  }, [isSubmitted, isSubmitSuccessful, trigger]);
  useEffect(() => {
    if (isSubmitSuccessful && clearOnSuccess) {
      reset();
    }
  }, [submitCount, isSubmitSuccessful, clearOnSuccess]);
  useDeepCompareEffect(() => {
    initialValues && reset(cloneDeep(initialValues));
    if (initialValues && initialErrors) {
      handleErrors(initialErrors);
    }
  }, [initialValues]);
  const handleSubmitWithOptions = useCallback((options) => (event) => {
    clearErrors();
    return handleSubmit(
      (values) => __async(void 0, null, function* () {
        var _a2, _b2;
        const formValidationErrors = validate == null ? void 0 : validate(values);
        if (!isDeepEmpty(formValidationErrors)) {
          return yield (_a2 = Promise.reject(formValidationErrors)) == null ? void 0 : _a2.then(onSubmitSuccess, handleErrors);
        }
        return yield (_b2 = save == null ? void 0 : save(values, options)) == null ? void 0 : _b2.then(onSubmitSuccess, handleErrors);
      })
    )(event).catch(handleErrors);
  }, [onSubmitSuccess, validate, save]);
  return /* @__PURE__ */ jsx(ReactHookFormProvider, __spreadProps(__spreadValues({}, methods), { children: /* @__PURE__ */ jsxs(FormPropsContext.Provider, { value: __spreadProps(__spreadValues({}, props), { handleSubmitWithOptions, watch: expandedWatch }), children: [
    !allowDirtyNavigation && /* @__PURE__ */ jsx(
      Prompt,
      {
        message: translate("cuda.form.dirtyNavigationCheck"),
        when: Object.values(dirtyFields).length > 0
      }
    ),
    children
  ] }) }));
};
var FormProvider_default = FormProvider;
export {
  FormPropsContext,
  deepFlattenCallback,
  FormProvider_default as default
};
