import { makeStyles } from "@mui/styles";
var pagerStyles_default = makeStyles((theme) => ({
  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  disabledIcon: {
    opacity: "0.3"
  },
  itemsPerPage: {
    marginLeft: theme.spacing(8)
  },
  dots: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));
export {
  pagerStyles_default as default
};
