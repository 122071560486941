import { jsx } from "react/jsx-runtime";
import { Grid } from "@barracuda-internal/bds-core";
import { Children } from "react";
import { makeOverrideableStyles } from "@cuda-react/theme";
import classNames from "classnames";
import { createStyles } from "@mui/styles";
const filterItemsForColumn = (items, currentColumn, totalColumns) => items.filter(
  (child) => {
    var _a, _b;
    return ((_a = child == null ? void 0 : child.props) == null ? void 0 : _a.column) === currentColumn || currentColumn + 1 === totalColumns && !(((_b = child == null ? void 0 : child.props) == null ? void 0 : _b.column) < currentColumn);
  }
);
const styles = createStyles({
  root: {
    flexWrap: (props) => props.autoWidth ? "nowrap" : void 0
  },
  gridItem: {
    maxWidth: (props) => props.autoWidth ? "unset" : void 0
  }
});
const useStyles = makeOverrideableStyles("Columns", styles);
const Columns = (props) => {
  const { className, children, columns, render } = props;
  const classes = useStyles(props);
  return /* @__PURE__ */ jsx(Grid, { container: true, className: classNames(className, classes.root), justifyContent: "center", children: columns && columns.map((column, index) => {
    const extraSmall = column.xs || column.width || 6;
    const small = column.sm || extraSmall;
    const medium = column.md || small;
    const large = column.lg || medium;
    const extraLarge = column.xl || large;
    const columnChildren = filterItemsForColumn(
      Children.toArray(children).filter((child) => !!child),
      index,
      columns.length
    );
    return /* @__PURE__ */ jsx(
      Grid,
      {
        item: true,
        xs: extraSmall,
        sm: small,
        md: medium,
        lg: large,
        xl: extraLarge,
        className: classes.gridItem,
        children: render && render(columnChildren) || columnChildren
      },
      index
    );
  }) || /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, className, children: render && render(children) || children }) });
};
var Columns_default = Columns;
export {
  Columns,
  Columns_default as default,
  filterItemsForColumn
};
