import { createContext, useContext } from "react";
const OneTrustContext = createContext({
  enabled: false,
  completed: false,
  allowedGroups: [],
  reopenPopup: () => {
  }
});
const useOneTrust = () => useContext(OneTrustContext);
export {
  OneTrustContext,
  useOneTrust
};
