var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { concat, get, set, uniqBy } from "lodash";
import { useContext, useEffect, useState } from "react";
import { getArrayDataContent, validateRequired } from "../utils";
import { useCrudProps } from "./CrudHooks";
import { useDeepCompareEffect } from "./UtilHooks";
import { useFormState } from "react-hook-form";
import { FormPropsContext } from "../components/forms/FormProvider/FormProvider";
import { FormErrorContext } from "../components/forms/FormErrorReporter";
import { useTranslation } from "react-i18next";
const useChoices = (selectedValues = [], { choices = [], filterKey = "key", onAdd, optionValue = "key", params, resource } = {}) => {
  const [savedChoices, setSavedChoices] = useState([]);
  const updateChoices = (newChoices) => setSavedChoices(
    (currentSavedChoices) => uniqBy(
      concat(newChoices, currentSavedChoices),
      (choice) => get(choice, optionValue)
    )
  );
  useDeepCompareEffect(() => {
    updateChoices(choices);
  }, [choices]);
  const missingChoices = selectedValues.filter((value) => !savedChoices.some((choice) => get(choice, optionValue) === value));
  const fetchParams = __spreadValues({
    filter: { [filterKey]: missingChoices }
  }, params);
  const [resourceChoices, loading] = useCrudProps(
    resource,
    fetchParams
  );
  const combinedChoices = getArrayDataContent(resourceChoices).concat(savedChoices);
  const result = selectedValues.map((value) => combinedChoices.find((choice) => get(choice, optionValue) === value) || set({}, optionValue, value));
  return [
    result,
    (choice) => {
      updateChoices([choice]);
      onAdd == null ? void 0 : onAdd(choice);
    },
    loading
  ];
};
const useInputWatch = (source, settings = {}) => {
  const [dynamicDisabled, setDynamicDisabled] = useState(false);
  const [dynamicRequired, setDynamicRequired] = useState(false);
  const [dynamicHidden, setDynamicHidden] = useState(false);
  const formProps = useContext(FormPropsContext);
  const inputSource = settings.sourcePrefix ? settings.sourcePrefix + "." + source : source;
  useEffect(() => {
    var _a;
    const subscription = (_a = formProps == null ? void 0 : formProps.watch) == null ? void 0 : _a.call(formProps, (formData, defaultValues) => {
      var _a2, _b, _c;
      const fieldValue = get(formData, inputSource);
      const siblingData = settings.sourcePrefix ? get(formData, settings.sourcePrefix) : void 0;
      setDynamicRequired(!!((_a2 = settings.require) == null ? void 0 : _a2.call(settings, fieldValue, formData, settings.sourcePrefix, siblingData, defaultValues)));
      setDynamicDisabled(!!((_b = settings.disable) == null ? void 0 : _b.call(settings, fieldValue, formData, settings.sourcePrefix, siblingData, defaultValues)));
      setDynamicHidden(!!((_c = settings.hide) == null ? void 0 : _c.call(settings, fieldValue, formData, settings.sourcePrefix, siblingData, defaultValues)));
    });
    return () => {
      var _a2;
      return (_a2 = subscription == null ? void 0 : subscription.unsubscribe) == null ? void 0 : _a2.call(subscription);
    };
  }, []);
  const { errors } = useFormState();
  const inputError = get(errors, inputSource);
  const tabErrorReporting = useContext(FormErrorContext);
  useEffect(() => {
    var _a;
    (_a = tabErrorReporting == null ? void 0 : tabErrorReporting.setError) == null ? void 0 : _a.call(tabErrorReporting, inputSource, !!inputError);
  }, [!!inputError, inputSource]);
  const disabledByContext = formProps.disabled;
  const inputIsRequired = settings.isRequired || dynamicRequired;
  const inputIsDisabled = settings.disabled || disabledByContext || dynamicDisabled;
  const inputIsHidden = dynamicHidden;
  const [translate] = useTranslation();
  const validationArray = !inputIsDisabled && settings.validate ? [...Array.isArray(settings.validate) ? settings.validate : [settings.validate]] : [];
  !inputIsDisabled && inputIsRequired && validationArray.unshift(validateRequired);
  const inputValidation = validationArray.reduce((acc, func, index) => __spreadValues({
    [func.name || index]: (currentValue, formData) => {
      const response = func(currentValue, formData, __spreadProps(__spreadValues({}, formProps), { t: translate }), source);
      return response ? JSON.stringify(response) : void 0;
    }
  }, acc), {});
  return {
    inputSource,
    inputError,
    inputValidation,
    inputIsRequired,
    inputIsDisabled,
    inputIsHidden
  };
};
export {
  useChoices,
  useInputWatch
};
