import { Fragment, jsx } from "react/jsx-runtime";
import { Component } from "react";
import { Container, Typography } from "@barracuda-internal/bds-core";
import { useTranslation } from "react-i18next";
import { makeOverrideableStyles } from "@cuda-react/theme";
const styles = (theme) => ({
  root: {
    padding: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%"
  }
});
const useStyles = makeOverrideableStyles("ErrorBoundary", styles);
const ErrorDisplay = () => {
  const classes = useStyles();
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsx(Container, { className: classes.root, children: /* @__PURE__ */ jsx(Typography, { children: translate("cuda.error.message") }) });
};
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }
  render() {
    if (!this.state.hasError) {
      return /* @__PURE__ */ jsx(Fragment, { children: this.props.children });
    }
    return this.props.hide ? null : /* @__PURE__ */ jsx(ErrorDisplay, {});
  }
}
var ErrorBoundary_default = ErrorBoundary;
export {
  ErrorBoundary,
  ErrorDisplay,
  ErrorBoundary_default as default,
  styles
};
