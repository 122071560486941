import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Typography } from "@barracuda-internal/bds-core";
import { useTranslation } from "react-i18next";
import { BarracudaIcon } from "@cuda-react/icons";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
import { useOneTrust } from "../../../hooks";
const styles = (theme) => createStyles({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    padding: "5px 15px",
    alignItems: "center",
    justifyContent: "space-between",
    borderTopColor: theme.palette.divider,
    borderTopStyle: "solid",
    borderTopWidth: 1
  },
  footerText: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    textDecoration: "none"
  },
  footerLink: {
    color: theme.palette.primary.main
  },
  cookieLink: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    textDecoration: "underline"
  },
  barracudaLogo: {
    height: 25,
    width: 105
  }
});
const useStyles = makeOverrideableStyles("Footer", styles);
const Footer = (props) => {
  const { footerItems } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const year = (/* @__PURE__ */ new Date()).getFullYear();
  const { enabled, reopenPopup } = useOneTrust();
  return /* @__PURE__ */ jsxs("div", { className: classes.root, children: [
    /* @__PURE__ */ jsxs(Typography, { className: classes.footerText, children: [
      /* @__PURE__ */ jsx(
        "a",
        {
          className: classes.footerLink,
          href: "https://www.barracuda.com/legal/privacy",
          rel: "noopener noreferrer",
          target: "_blank",
          children: translate("cuda.footer.privacyPolicy")
        }
      ),
      "\xA0",
      enabled && /* @__PURE__ */ jsxs(Fragment, { children: [
        "| ",
        /* @__PURE__ */ jsx("a", { className: classes.cookieLink, onClick: reopenPopup, children: translate("cuda.footer.cookies") })
      ] }),
      "\xA0 | \xA9 ",
      year,
      " ",
      translate("cuda.footer.copyright"),
      " ",
      footerItems || null
    ] }),
    /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx("a", { href: "https://barracuda.com/", rel: "noopener noreferrer", target: "_blank", children: /* @__PURE__ */ jsx(BarracudaIcon, { classes: { icon: classes.barracudaLogo } }) }) })
  ] });
};
var Footer_default = Footer;
export {
  Footer,
  Footer_default as default
};
