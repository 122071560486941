var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { get, merge } from "lodash";
import { stringify } from "query-string";
import { getDataContent } from "../utils";
import performFetch from "./performFetch";
var CrudTypes = /* @__PURE__ */ ((CrudTypes2) => {
  CrudTypes2["GET"] = "GET";
  CrudTypes2["ACTION"] = "ACTION";
  CrudTypes2["CREATE"] = "CREATE";
  CrudTypes2["UPDATE"] = "UPDATE";
  CrudTypes2["DELETE"] = "DELETE";
  return CrudTypes2;
})(CrudTypes || {});
const AUTHENTICATION_FAILED = "authentication.failed";
const getQuery = (params) => {
  const queryMap = __spreadValues({}, params.filter || {});
  Object.keys(queryMap).forEach((entry) => {
    if (Array.isArray(queryMap[entry]) && queryMap[entry].length === 1 && typeof queryMap[entry][0] === "string" && queryMap[entry][0].includes(",")) {
      queryMap[entry].push("");
    } else if (typeof queryMap[entry] === "string" && queryMap[entry].includes(",")) {
      queryMap[entry] = [queryMap[entry], ""];
    }
  });
  if (params.pagination) {
    queryMap.page = params.pagination.page - 1;
    queryMap.size = params.pagination.perPage;
  }
  if (params.sort && params.sort.field) {
    queryMap.sort = params.sort.field + "," + params.sort.order;
  }
  return Object.keys(queryMap).length > 0 ? `?${stringify(queryMap)}` : "";
};
const jsonHeader = { "Content-Type": "application/json;charset=UTF-8" };
const convertRESTRequestToHTTP = (type, url, params) => {
  const endPoint = replaceEndPointParams(url, params);
  const options = {
    method: "GET"
  };
  const query = getQuery(params);
  const resolvedUrl = `${endPoint}${query}`;
  switch (type) {
    case "GET" /* GET */:
      break;
    case "ACTION" /* ACTION */:
      options.method = "PUT";
      options.headers = jsonHeader;
      break;
    case "UPDATE" /* UPDATE */:
      options.method = "PUT";
      options.headers = jsonHeader;
      options.body = JSON.stringify(params.data);
      break;
    case "CREATE" /* CREATE */:
      options.method = "POST";
      options.headers = jsonHeader;
      options.body = JSON.stringify(params.data);
      break;
    case "DELETE" /* DELETE */:
      options.method = "DELETE";
      options.headers = jsonHeader;
      break;
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
  return {
    url: resolvedUrl,
    options: __spreadValues(__spreadValues({}, options), (params == null ? void 0 : params.options) || {})
  };
};
const replaceEndPointParams = (endpoint, params) => {
  const regexParams = /{([^}]+)}/g;
  const matches = [];
  let match;
  while ((match = regexParams.exec(endpoint)) !== null) {
    if (match[1] && match[1] !== "") {
      matches.push(match[1]);
    }
  }
  matches.forEach((match2) => {
    const replacement = get(params, match2);
    const paramPlaceholder = "{" + match2 + "}";
    if (match2.startsWith("userData") && !replacement) {
      throw AUTHENTICATION_FAILED;
    }
    if (replacement || replacement === 0) {
      endpoint = endpoint.replace(paramPlaceholder, replacement.toString());
    } else {
      endpoint = endpoint.replace(paramPlaceholder, "");
    }
  });
  endpoint = endpoint.replace(/\/*$/, "");
  return endpoint;
};
const crudClient = (type, url, params, globalParams, customFetchOptions = {}) => {
  try {
    const mergedParams = merge({}, params, globalParams);
    if (!url) {
      return Promise.resolve({ data: void 0 });
    }
    const { url: resolvedUrl, options } = convertRESTRequestToHTTP(type, url, mergedParams);
    return performFetch(resolvedUrl, merge(options, customFetchOptions)).then((response) => {
      var _a, _b;
      if (response.body && typeof response.body !== "string") {
        const data = { data: response.body };
        if (Array.isArray(data.data)) {
          data.data = { content: data.data, total: data.data.length };
        } else if (Array.isArray(getDataContent(data))) {
          data.data.total = ((_b = (_a = data == null ? void 0 : data.data) == null ? void 0 : _a.page) == null ? void 0 : _b.totalElements) || getDataContent(data).length;
        }
        if (type === "CREATE" /* CREATE */ || type === "UPDATE" /* UPDATE */) {
          data.data = merge({}, params == null ? void 0 : params.data, data.data);
        }
        return data;
      }
      return { data: response.body };
    }).catch((error) => {
      if (error instanceof SyntaxError) {
        error.message = "httpError.500";
      }
      return Promise.reject(error);
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
var crudClient_default = crudClient;
export {
  AUTHENTICATION_FAILED,
  CrudTypes,
  convertRESTRequestToHTTP,
  crudClient_default as default
};
