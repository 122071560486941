var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Children } from "react";
import FormButtonToolbar from "../FormButtonToolbar/FormButtonToolbar";
import ErrorBoundary from "../../functional/ErrorBoundary/ErrorBoundary";
import FormProvider from "../FormProvider/FormProvider";
import { createStyles } from "@mui/styles";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { Paper } from "@barracuda-internal/bds-core";
const styles = createStyles({
  form: {
    display: "flex",
    flexDirection: (props) => props.flexRow ? "row" : "column",
    flex: "1 1 auto",
    overflowY: "auto"
  },
  formContents: {
    flex: "1 1 auto",
    overflowY: "auto"
  },
  topButtonToolbar: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto"
  }
});
const useStyles = makeOverrideableStyles("Form", styles);
const Form = (props) => {
  const {
    children,
    formButtonToolbarProps,
    flat,
    topToolbar,
    noToolbar
  } = props;
  const classes = useStyles(props);
  return /* @__PURE__ */ jsx(ErrorBoundary, { children: /* @__PURE__ */ jsxs(FormProvider, __spreadProps(__spreadValues({}, props), { children: [
    topToolbar && /* @__PURE__ */ jsx("div", { className: classes.topButtonToolbar, children: /* @__PURE__ */ jsx(
      FormButtonToolbar,
      __spreadProps(__spreadValues({}, formButtonToolbarProps), {
        buttonsOnly: true
      })
    ) }),
    !flat ? /* @__PURE__ */ jsx(Paper, { children: /* @__PURE__ */ jsxs("form", { className: classes.form, children: [
      /* @__PURE__ */ jsx("div", { className: classes.formContents, children: Children.toArray(children).filter((child) => !!child) }),
      !noToolbar && !topToolbar && /* @__PURE__ */ jsx(
        FormButtonToolbar,
        __spreadValues({}, formButtonToolbarProps)
      )
    ] }) }) : /* @__PURE__ */ jsxs("form", { className: classes.form, children: [
      /* @__PURE__ */ jsx("div", { className: classes.formContents, children: Children.toArray(children).filter((child) => !!child) }),
      !noToolbar && !topToolbar && /* @__PURE__ */ jsx(
        FormButtonToolbar,
        __spreadValues({}, formButtonToolbarProps)
      )
    ] })
  ] })) });
};
Form.defaultProps = {
  submitOnEnter: true
};
var Form_default = Form;
export {
  Form,
  Form_default as default,
  styles
};
