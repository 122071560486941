var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { CrudTypes } from "../../../clients";
import { useCrudFetch, useCrudProps, useDeepCompareMemoize } from "../../../hooks";
import { getDataContent } from "../../../utils";
import Form from "../Form/Form";
import TabbedForm from "../TabbedForm/TabbedForm";
import { createStyles } from "@mui/styles";
import { makeOverrideableStyles } from "@cuda-react/theme";
const styles = createStyles({
  paper: {
    flex: "1 1 auto",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column"
  }
});
const useStyles = makeOverrideableStyles("ConnectedForm", styles);
const ConnectedForm = (props) => {
  const _a = props, {
    children,
    create,
    tabbed,
    resource,
    id,
    defaultResource,
    formatError,
    formatRequestData,
    formatResourceData,
    initialValues: userInitialValues,
    query,
    params = {},
    onSubmitSuccess
  } = _a, formProps = __objRest(_a, [
    "children",
    "create",
    "tabbed",
    "resource",
    "id",
    "defaultResource",
    "formatError",
    "formatRequestData",
    "formatResourceData",
    "initialValues",
    "query",
    "params",
    "onSubmitSuccess"
  ]);
  const classes = useStyles(props);
  const initialResource = !userInitialValues && (create || query ? defaultResource : resource);
  const [, saving, crudSubmit] = useCrudFetch(query ? CrudTypes.GET : create ? CrudTypes.CREATE : CrudTypes.UPDATE, resource, __spreadValues({ id }, params));
  const [initialData, loading, fetchInitialData] = useCrudProps(initialResource || void 0, __spreadValues({ id }, params));
  const initialValues = userInitialValues || getDataContent(initialData) || {};
  const save = useCallback((values) => {
    const formattedData = formatRequestData && formatRequestData(values) || values;
    return crudSubmit(
      __spreadValues({ data: formattedData, id }, params),
      { formPromise: true }
    ).then((response) => {
      if (!(response == null ? void 0 : response.error)) {
        fetchInitialData();
        onSubmitSuccess == null ? void 0 : onSubmitSuccess(response == null ? void 0 : response.data);
      }
      return response == null ? void 0 : response.data;
    }).catch((error) => {
      throw formatError ? formatError(error, values, formattedData) : error;
    });
  }, [formatRequestData, formatError, crudSubmit]);
  const initialValuesMemo = useDeepCompareMemoize(formatResourceData ? formatResourceData(initialValues) : initialValues);
  const formContent = React.createElement(
    // @ts-ignore not sure why typescript generates this error
    tabbed ? TabbedForm : Form,
    __spreadProps(__spreadValues({}, formProps), {
      initialValues: initialValuesMemo,
      save,
      disabled: loading || saving
    }),
    children
  );
  return /* @__PURE__ */ jsx("div", { children: formContent });
};
var ConnectedForm_default = ConnectedForm;
export {
  ConnectedForm,
  ConnectedForm_default as default
};
