import { enhanceActionWithGlobalParams } from "./GlobalParamsActions";
const AUTH_CHECK = "CUDA/AUTH_CHECK";
const AUTH_ERROR = "CUDA/AUTH_ERROR";
const AUTH_LOGIN = "CUDA/AUTH_LOGIN";
const AUTH_CHANGE_ACCOUNT = "CUDA/AUTH_CHANGE_ACCOUNT";
const AUTH_LOGOUT = "CUDA/AUTH_LOGOUT";
const SAVE_ORIGIN = "CUDA/SAVE_ORIGIN";
const SAVE_LOGIN_URL = "CUDA/SAVE_LOGIN_URL";
const authCheck = (params) => enhanceActionWithGlobalParams({
  type: AUTH_CHECK,
  params
});
const authError = (params) => enhanceActionWithGlobalParams({
  type: AUTH_ERROR,
  params
});
const authLogin = (params) => enhanceActionWithGlobalParams({
  type: AUTH_LOGIN,
  params
});
const authChangeAccount = (accountId, updateInPlace) => enhanceActionWithGlobalParams({
  type: AUTH_CHANGE_ACCOUNT,
  params: { accountId, updateInPlace }
});
const authLogout = () => enhanceActionWithGlobalParams({
  type: AUTH_LOGOUT
});
const saveOrigin = (origin) => ({
  type: SAVE_ORIGIN,
  origin
});
const saveLoginUrl = (loginUrl) => ({
  type: SAVE_LOGIN_URL,
  loginUrl
});
export {
  AUTH_CHANGE_ACCOUNT,
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  SAVE_LOGIN_URL,
  SAVE_ORIGIN,
  authChangeAccount,
  authCheck,
  authError,
  authLogin,
  authLogout,
  saveLoginUrl,
  saveOrigin
};
