var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Button } from "@barracuda-internal/bds-core";
import { makeOverrideableStyles } from "@cuda-react/theme";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Toolbar from "../../layout/Toolbar/Toolbar";
import SaveButton from "../SaveButton/SaveButton";
import { createStyles } from "@mui/styles";
import { useFormContext } from "react-hook-form";
import { FormPropsContext } from "../FormProvider/FormProvider";
const styles = (theme) => createStyles({
  formButton: {},
  buttonContainer: {
    paddingBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    display: "inline-flex",
    flexDirection: "row-reverse",
    "&> *:not(:first-child)": {
      marginRight: theme.spacing(1.5)
    },
    alignItems: "end"
  }
});
const useStyles = makeOverrideableStyles("FormButtonToolbar", styles);
const FormButtonToolbar = (props) => {
  const _a = props, { buttonsOnly, classes: _ignored } = _a, saveButtonProps = __objRest(_a, ["buttonsOnly", "classes"]);
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const { reset, formState: { dirtyFields } } = useFormContext();
  const {
    back,
    canReset,
    handleSubmitWithOptions: handleSubmitWithRedirect,
    disabled,
    onCancel,
    submitOnEnter
  } = useContext(FormPropsContext);
  const pristine = Object.values(dirtyFields).length === 0;
  const toolbarContent = /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(SaveButton, __spreadValues({}, __spreadValues({ disabled, pristine, handleSubmitWithRedirect, submitOnEnter }, saveButtonProps))),
    canReset && reset && /* @__PURE__ */ jsx(
      Button,
      {
        onClick: () => reset(),
        disabled: pristine || disabled,
        variant: "contained",
        color: "secondary",
        size: "small",
        className: classes.formButton,
        children: translate("cuda.buttons.reset")
      }
    ),
    onCancel && /* @__PURE__ */ jsx(
      Button,
      {
        onClick: onCancel,
        disabled,
        variant: "contained",
        color: "secondary",
        size: "small",
        className: classes.formButton,
        children: translate("cuda.buttons.cancel")
      }
    ),
    back && /* @__PURE__ */ jsx(
      Button,
      {
        onClick: back,
        disabled,
        variant: "contained",
        color: "secondary",
        size: "small",
        className: classes.formButton,
        children: translate("cuda.buttons.back")
      }
    )
  ] });
  return buttonsOnly ? /* @__PURE__ */ jsx("div", { className: classes.buttonContainer, children: toolbarContent }) : /* @__PURE__ */ jsx(Toolbar, { children: toolbarContent });
};
var FormButtonToolbar_default = FormButtonToolbar;
export {
  FormButtonToolbar_default as default
};
