import { jsx, jsxs } from "react/jsx-runtime";
import { Card as BdsCard, CardContent, CardHeader } from "@barracuda-internal/bds-core";
import classNames from "classnames";
import React, { Children } from "react";
import { useTranslation } from "react-i18next";
import { makeOverrideableStyles } from "@cuda-react/theme";
import ErrorBoundary from "../../functional/ErrorBoundary/ErrorBoundary";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    textAlign: "left",
    display: "inline-block",
    verticalAlign: "top",
    maxWidth: "100%"
  },
  cardHeader: {
    textAlign: "left",
    alignItems: "flex-start",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 2),
    overflowY: "visible"
  },
  titleTypography: {
    fontSize: "24px !important",
    fontWeight: 300,
    overflowY: "visible"
  },
  cardContent: {
    minWidth: `100%`,
    padding: 0,
    "&:last-child": {
      paddingBottom: 0
    },
    overflowX: "hidden",
    overflowY: "auto"
  }
});
const useStyles = makeOverrideableStyles("Card", styles);
const Card = (props) => {
  const { action, children, id, containerElement, className, title } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsxs(BdsCard, { className: classNames(classes.root, className), id, children: [
    title && /* @__PURE__ */ jsx(ErrorBoundary, { children: /* @__PURE__ */ jsx(
      CardHeader,
      {
        action,
        title: typeof title === "string" ? translate(title) : title,
        className: classes.cardHeader,
        titleTypographyProps: { className: classes.titleTypography }
      }
    ) }),
    /* @__PURE__ */ jsx(ErrorBoundary, { children: /* @__PURE__ */ jsx(CardContent, { className: classes.cardContent, children: containerElement ? React.cloneElement(containerElement, {
      children: Children.toArray(children).filter((child) => !!child).map((child, index) => React.cloneElement(child, { key: "card-item-" + index }))
    }) : Children.toArray(children).filter((child) => !!child).map((child, index) => React.cloneElement(child, { key: "card-item-" + index })) }) })
  ] });
};
Card.defaultProps = {
  id: "cuda-generic-card"
};
var Card_default = Card;
export {
  Card,
  Card_default as default
};
