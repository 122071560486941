var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var performFetch_default = (url, options = {}) => {
  options.credentials = "same-origin";
  options.headers = __spreadValues({ "Accept": "application/json" }, options.headers);
  return fetch(url, options).then((response) => {
    if (response.status === 204) {
      return { body: "", message: "", status: response.status };
    }
    const contentType = response.headers.get("Content-Type");
    if (contentType && contentType.includes("application/json")) {
      return response.json().then((json) => {
        const formattedResponse = {
          body: json,
          message: json.message,
          status: response.status
        };
        if (response.ok) {
          return formattedResponse;
        }
        throw formattedResponse;
      });
    }
    return response.text().then((content) => {
      const formattedResponse = {
        body: content,
        status: response.status
      };
      if (response.ok) {
        return formattedResponse;
      }
      throw formattedResponse;
    });
  }).catch((error) => {
    const formattedError = {
      name: error.name,
      code: error.code,
      body: error.body,
      message: error.message,
      status: error.status
    };
    if (error.body && error.body.localizedMessage) {
      formattedError.message = error.body.localizedMessage;
    }
    if (!error.message && error.status !== void 0) {
      formattedError.message = "httpError." + error.status;
    }
    return Promise.reject(formattedError);
  });
};
export {
  performFetch_default as default
};
