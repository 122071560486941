import { jsx, jsxs } from "react/jsx-runtime";
import { Button, CircularProgress, Dialog, Typography } from "@barracuda-internal/bds-core";
import React, { useState } from "react";
import DialogBody from "../../dialog/DialogBody/DialogBody";
import Toolbar from "../../layout/Toolbar/Toolbar";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { useTranslation } from "react-i18next";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {},
  confirmMessage: {
    margin: theme.spacing(1, 3),
    "&:first-of-type": {
      marginTop: theme.spacing(2)
    },
    "&:last-of-type": {
      marginBottom: theme.spacing(2)
    }
  },
  formButton: {}
});
const useStyles = makeOverrideableStyles("SaveButton", styles);
const SaveButton = (props) => {
  const {
    disabled,
    label = "cuda.buttons.save",
    submitOnEnter = true,
    color = "primary",
    confirmMessage,
    handleSubmitWithRedirect,
    pristine
  } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const performSave = () => handleSubmitWithRedirect == null ? void 0 : handleSubmitWithRedirect()();
  const handleClick = (event) => {
    if (disabled) {
      event.preventDefault();
    } else {
      if (event) {
        event.preventDefault();
      }
      if (confirmMessage) {
        setOpen(true);
      } else {
        performSave();
      }
    }
  };
  const confirmMessageArray = confirmMessage && (Array.isArray(confirmMessage) ? confirmMessage : [confirmMessage]);
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(
      Button,
      {
        className: classes.root,
        type: submitOnEnter ? "submit" : "button",
        startIcon: disabled ? /* @__PURE__ */ jsx(CircularProgress, { id: "cuda-loading-spinner", size: 16, thickness: 2 }) : void 0,
        onClick: handleClick,
        disabled: !!disabled || pristine,
        color,
        variant: "contained",
        size: "small",
        children: translate(label)
      }
    ),
    submitOnEnter ? void 0 : /* @__PURE__ */ jsx("input", { type: "text", hidden: true, disabled: true }),
    confirmMessageArray && /* @__PURE__ */ jsx(Dialog, { open, children: /* @__PURE__ */ jsxs(
      DialogBody,
      {
        title: "cuda.save.title",
        onClose: () => setOpen(false),
        children: [
          confirmMessageArray.map((message) => /* @__PURE__ */ jsx(Typography, { className: classes.confirmMessage, children: translate(message) }, message)),
          /* @__PURE__ */ jsxs(Toolbar, { children: [
            /* @__PURE__ */ jsx(
              Button,
              {
                color: "primary",
                onClick: () => {
                  performSave();
                  setOpen(false);
                },
                variant: "contained",
                size: "small",
                children: translate("cuda.buttons.confirm")
              }
            ),
            /* @__PURE__ */ jsx(
              Button,
              {
                className: classes.formButton,
                color: "secondary",
                onClick: () => setOpen(false),
                variant: "contained",
                size: "small",
                children: translate("cuda.buttons.cancel")
              }
            )
          ] })
        ]
      }
    ) })
  ] });
};
var SaveButton_default = SaveButton;
export {
  SaveButton_default as default
};
