var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Children, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeOverrideableStyles } from "@cuda-react/theme";
import Tab from "../../tabs/Tab/Tab";
import Tabs from "../../tabs/Tabs/Tabs";
import FormButtonToolbar from "../FormButtonToolbar/FormButtonToolbar";
import FormErrorReporter from "../FormErrorReporter";
import FormProvider from "../FormProvider/FormProvider";
import { createStyles } from "@mui/styles";
import { Paper } from "@barracuda-internal/bds-core";
const styles = (theme) => createStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    overflowY: "auto"
  },
  paper: {
    marginBottom: 2
  }
});
const useStyles = makeOverrideableStyles("TabbedForm", styles);
const TabbedForm = (props) => {
  const {
    children,
    disabled,
    formButtonToolbarProps,
    noToolbar,
    topToolbar
  } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const childArray = Children.toArray(children);
  const [tabsWithErrors, setTabsWithErrors] = useState([]);
  const setTabError = useCallback((value, error) => {
    setTabsWithErrors((currentValues) => [...currentValues.filter((currentValue) => currentValue !== value), ...error ? [value] : []]);
  }, []);
  return /* @__PURE__ */ jsx(FormProvider, __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsxs("form", { className: classes.form, children: [
    topToolbar && /* @__PURE__ */ jsx(
      FormButtonToolbar,
      __spreadProps(__spreadValues({}, formButtonToolbarProps), {
        buttonsOnly: true
      })
    ),
    /* @__PURE__ */ jsxs(Paper, { className: classes.paper, children: [
      /* @__PURE__ */ jsx(Tabs, { children: childArray.map(
        (tab, index) => tab ? /* @__PURE__ */ jsx(
          Tab,
          {
            label: translate(tab.props.label),
            value: index,
            icon: tab.props.icon,
            error: tabsWithErrors.includes(index),
            disabled: disabled || tab.props.disabled,
            alwaysRender: true,
            setDisplayNone: true,
            children: /* @__PURE__ */ jsx(FormErrorReporter, { value: index, setError: setTabError, children: tab.props.children })
          },
          tab.props.label || index
        ) : null
      ) }),
      !noToolbar && !topToolbar && /* @__PURE__ */ jsx(
        FormButtonToolbar,
        __spreadValues({}, formButtonToolbarProps)
      )
    ] })
  ] }) }));
};
TabbedForm.defaultProps = {
  submitOnEnter: true,
  pristine: false
};
var TabbedForm_default = TabbedForm;
export {
  TabbedForm,
  TabbedForm_default as default,
  styles
};
