import {
  DIALOG_NOTIFICATION,
  NOTIFICATION_HIDE,
  NOTIFICATION_SHOW
} from "../../actions";
import { uniqBy } from "lodash";
const NotificationReducer = (previousState = {}, { type, notification }) => {
  switch (type) {
    case NOTIFICATION_SHOW:
      if (notification.type === DIALOG_NOTIFICATION) {
        return Object.assign(
          {},
          previousState,
          {
            [notification.type]: notification
          }
        );
      }
      return Object.assign(
        {},
        previousState,
        {
          [notification.type]: uniqBy([...previousState[notification.type] || [], notification], ({ content }) => content)
        }
      );
    case NOTIFICATION_HIDE:
      if (notification.type === DIALOG_NOTIFICATION || !notification.content) {
        return Object.assign({}, previousState, { [notification.type]: null });
      }
      return Object.assign({}, previousState, {
        [notification.type]: (previousState[notification.type] || []).filter((existing) => existing.content !== notification.content)
      });
    default:
      return previousState;
  }
};
var NotificationReducer_default = NotificationReducer;
export {
  NotificationReducer_default as default
};
