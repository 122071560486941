import { jsx, jsxs } from "react/jsx-runtime";
import { Toolbar as MuiToolbar } from "@barracuda-internal/bds-core";
import React from "react";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    width: `calc(100% - ${theme.spacing(4)})`,
    minHeight: 48,
    padding: theme.spacing(1, 0),
    margin: theme.spacing(0, 2),
    flexDirection: "row-reverse",
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    borderWidth: "1px 0 0",
    "&> *:not(:first-child)": {
      marginRight: theme.spacing(1.5)
    },
    justifyContent: (props) => props.center ? "center" : void 0
  },
  leftRightSeparator: {
    marginLeft: "auto"
  }
});
const useStyles = makeOverrideableStyles("Toolbar", styles);
const Toolbar = (props) => {
  const classes = useStyles(props);
  const children = React.Children.toArray(props.children);
  const leftChildren = children.filter((child) => child && child.props.left);
  const rightChildren = children.filter((child) => child && !child.props.left);
  return /* @__PURE__ */ jsxs(MuiToolbar, { className: classes.root, variant: "regular", children: [
    rightChildren,
    !props.center ? /* @__PURE__ */ jsx("div", { className: classes.leftRightSeparator }) : null,
    leftChildren
  ] });
};
var Toolbar_default = Toolbar;
export {
  Toolbar,
  Toolbar_default as default
};
