import merge from "lodash/merge";
import { makeStyles } from "@mui/styles";
const overrideableStyles = (name, styles) => (theme) => {
  const resolvedStyles = typeof styles === "function" ? styles(theme) : styles;
  const overridingStyles = theme.overrides && theme.overrides["CR" + name];
  return merge(resolvedStyles, overridingStyles);
};
const makeOverrideableStyles = (name, styles) => makeStyles(overrideableStyles(name, styles), { name: "CR" + name });
export {
  makeOverrideableStyles,
  overrideableStyles
};
