import { REDIRECT } from "../actions";
import { push } from "react-router-redux";
import { put, take, fork } from "redux-saga/effects";
const redirect = {
  external: (url) => {
    location.href = url;
  }
};
function* redirectSagas() {
  while (true) {
    const { pathOrUrl } = yield take(REDIRECT);
    if (pathOrUrl && pathOrUrl.startsWith("http")) {
      redirect.external(pathOrUrl);
      yield put({ type: "END" });
      return;
    } else {
      yield fork(function* () {
        yield put(push(pathOrUrl));
      });
    }
  }
}
var RedirectSaga_default = redirectSagas;
export {
  RedirectSaga_default as default,
  redirect
};
