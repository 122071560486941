var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { FormControl, FormHelperText } from "@barracuda-internal/bds-core";
import { makeOverrideableStyles } from "@cuda-react/theme";
import React from "react";
import { formatErrorMessage, getRawError } from "../../../utils";
import InputLabel from "../InputLabel/InputLabel";
import { Controller } from "react-hook-form";
import { createStyles } from "@mui/styles";
import { useInputWatch } from "../../../hooks";
const styles = createStyles({
  field: {
    maxWidth: "100%"
  }
});
const useStyles = makeOverrideableStyles("Input", styles);
const Input = (props) => {
  const _a = props, {
    additionalInfoLabel,
    description,
    disable,
    disabled,
    displayError,
    hide,
    hideInput,
    isRequired,
    isVarHeight,
    label,
    noLabel,
    onChangeProp,
    require: require2,
    showErrorOnDisabled,
    source,
    sourcePrefix,
    component: Component,
    validate,
    valueProp,
    inputLabelProps,
    inputClasses,
    inputProps,
    newStyle
  } = _a, additionalProps = __objRest(_a, [
    "additionalInfoLabel",
    "description",
    "disable",
    "disabled",
    "displayError",
    "hide",
    "hideInput",
    "isRequired",
    "isVarHeight",
    "label",
    "noLabel",
    "onChangeProp",
    "require",
    "showErrorOnDisabled",
    "source",
    "sourcePrefix",
    "component",
    "validate",
    "valueProp",
    "inputLabelProps",
    "inputClasses",
    "inputProps",
    "newStyle"
  ]);
  const classes = useStyles(props);
  const {
    inputSource,
    inputValidation,
    inputIsRequired,
    inputIsDisabled,
    inputIsHidden
  } = useInputWatch(source, {
    sourcePrefix,
    disabled,
    disable,
    hide,
    isRequired,
    require: require2,
    validate
  });
  return !inputIsHidden ? /* @__PURE__ */ jsx(
    Controller,
    {
      name: inputSource,
      rules: { validate: inputValidation },
      render: ({
        field: { onChange, onBlur, value, name },
        fieldState: { error }
      }) => {
        const errorToDisplay = (!inputIsDisabled || showErrorOnDisabled) && getRawError(error);
        const field = /* @__PURE__ */ jsxs(
          FormControl,
          {
            component: "fieldset",
            error: displayError && !!error,
            disabled: inputIsDisabled,
            className: classes.field,
            children: [
              React.createElement(Component, __spreadValues(__spreadValues({
                onBlur,
                [onChangeProp || "onChange"]: (eventOrValue) => onChange((eventOrValue == null ? void 0 : eventOrValue.target) ? eventOrValue.target.value : eventOrValue),
                [valueProp || "value"]: value,
                error: errorToDisplay || void 0,
                id: source.replace(/[.]/g, "-"),
                source: name,
                disabled: inputIsDisabled,
                classes: inputClasses
              }, additionalProps), inputProps)),
              !!errorToDisplay && displayError && /* @__PURE__ */ jsx(FormHelperText, { children: formatErrorMessage(errorToDisplay) })
            ]
          }
        );
        return noLabel ? field : /* @__PURE__ */ jsx(
          InputLabel,
          __spreadProps(__spreadValues({
            additionalInfoLabel,
            description,
            isRequired: inputIsRequired,
            isVarHeight,
            hideInput,
            label,
            newStyle
          }, inputLabelProps || {}), {
            children: field
          })
        );
      }
    }
  ) : null;
};
Input.defaultProps = {
  onChangeProp: "onChange",
  valueProp: "value",
  displayError: true
};
var Input_default = Input;
export {
  Input,
  Input_default as default
};
