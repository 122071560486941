var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { useMemo, useCallback } from "react";
var PagerLogic_default = (WrappedComponent) => {
  const PagerLogic = (props) => {
    const { total, skip, take, onPageChange, buttonCount, pageSizes } = props;
    const OFFSET_RANGE = Math.floor(buttonCount / 2);
    const ADDITIONAL_OFFSET = buttonCount % 2;
    const from = useMemo(() => 1 + skip, [skip]);
    const to = useMemo(() => Math.min(from + take - 1, total), [from, take, total]);
    const currentPage = useMemo(() => skip / take, [take, skip]);
    const maxPage = useMemo(() => Math.ceil(total / take), [total, take]);
    const placeOnChangeEvent = useCallback(
      (event, newSkip) => {
        if (newSkip !== skip) {
          onPageChange({
            target: null,
            skip: newSkip,
            take,
            syntheticEvent: event,
            nativeEvent: event.nativeEvent
          });
        }
      },
      [onPageChange, skip, take]
    );
    const pages = useMemo(() => {
      const offsets = [...Array(maxPage).keys()].map((index) => ({
        isActive: currentPage === index,
        page: index + 1,
        onClick: (event) => {
          placeOnChangeEvent(event, index * take);
        }
      }));
      const startOffsetfromStart = Math.max(currentPage - OFFSET_RANGE, 0);
      const startOffsetDiff = Math.abs(currentPage - OFFSET_RANGE - startOffsetfromStart);
      let collectedOffsets = offsets.slice(startOffsetfromStart, startOffsetfromStart + OFFSET_RANGE - startOffsetDiff);
      const endOffsetFromEnd = Math.min(currentPage + OFFSET_RANGE, offsets.length - ADDITIONAL_OFFSET);
      const endOffsetDiff = Math.abs(currentPage + OFFSET_RANGE - endOffsetFromEnd);
      const takenFromEnd = endOffsetFromEnd - currentPage + ADDITIONAL_OFFSET;
      collectedOffsets = [...collectedOffsets, ...offsets.slice(currentPage, currentPage + takenFromEnd)];
      if (startOffsetDiff) {
        const newEndOffset = currentPage + takenFromEnd;
        collectedOffsets = [...collectedOffsets, ...offsets.slice(newEndOffset, newEndOffset + startOffsetDiff)];
      }
      if (endOffsetDiff && startOffsetfromStart) {
        const newStartOffset = Math.max(startOffsetfromStart - endOffsetDiff, 0);
        collectedOffsets = [...offsets.slice(newStartOffset, newStartOffset + endOffsetDiff), ...collectedOffsets];
      }
      return collectedOffsets;
    }, [take, currentPage, placeOnChangeEvent, maxPage, OFFSET_RANGE, ADDITIONAL_OFFSET]);
    const buttons = useMemo(() => ({
      firstPage: {
        isDisabled: !currentPage,
        onClick: (event) => {
          placeOnChangeEvent(event, 0);
        }
      },
      prevPage: {
        isDisabled: !currentPage,
        onClick: (event) => {
          placeOnChangeEvent(event, skip - take);
        }
      },
      nextPage: {
        isDisabled: currentPage === maxPage - 1,
        onClick: (event) => {
          placeOnChangeEvent(event, skip + take);
        }
      },
      lastPage: {
        isDisabled: currentPage === maxPage - 1,
        onClick: (event) => {
          placeOnChangeEvent(event, (maxPage - 1) * take);
        }
      }
    }), [currentPage, maxPage, placeOnChangeEvent, skip, take]);
    const itemsPerPageConfig = useMemo(() => {
      if (!pageSizes) {
        return void 0;
      }
      return {
        onChange: (event) => {
          onPageChange({
            target: null,
            skip: 0,
            take: event.target.value,
            syntheticEvent: event,
            nativeEvent: event.nativeEvent
          });
        },
        value: take,
        menuItems: pageSizes.map((size) => ({
          id: size,
          value: size
        }))
      };
    }, [pageSizes, onPageChange, take]);
    const dotsStatuses = useMemo(() => ({
      isPrevVisible: (pages[0] || {}).page !== 1,
      isNextVisible: ([...pages].pop() || {}).page !== maxPage
    }), [pages, maxPage]);
    return useMemo(() => (
      // @ts-ignore
      /* @__PURE__ */ jsx(
        WrappedComponent,
        __spreadProps(__spreadValues({}, props), {
          from,
          to,
          buttons,
          pages,
          itemsPerPageConfig,
          dotsStatuses,
          maxPage
        })
      )
    ), [props, from, to, buttons, pages, itemsPerPageConfig, dotsStatuses, maxPage]);
  };
  return PagerLogic;
};
export {
  PagerLogic_default as default
};
