var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import React from "react";
const PropGateway = (_a) => {
  var _b = _a, { children, editProps } = _b, props = __objRest(_b, ["children", "editProps"]);
  return /* @__PURE__ */ jsx(React.Fragment, { children: React.Children.toArray(children).filter((child) => !!child).map((child) => React.cloneElement(child, editProps ? editProps(props) : {})) });
};
var PropGateway_default = PropGateway;
export {
  PropGateway_default as default
};
