const NOTIFICATION_SHOW = "CUDA/NOTIFICATION_SHOW";
const NOTIFICATION_HIDE = "CUDA/NOTIFICATION_HIDE";
const BANNER_NOTIFICATION = "CUDA/BANNER_NOTIFICATION";
const DIALOG_NOTIFICATION = "CUDA/DIALOG_NOTIFICATION";
const TOAST_NOTIFICATION = "CUDA/TOAST_NOTIFICATION";
const INFO_NOTIFICATION = "CUDA/INFO_NOTIFICATION";
const OK_NOTIFICATION = "CUDA/OK_NOTIFICATION";
const WARNING_NOTIFICATION = "CUDA/WARNING_NOTIFICATION";
const ERROR_NOTIFICATION = "CUDA/ERROR_NOTIFICATION";
const notificationShowBanner = (content, level = INFO_NOTIFICATION, dismissible = true, spinner = false, translateParams) => ({
  type: NOTIFICATION_SHOW,
  notification: {
    type: BANNER_NOTIFICATION,
    content,
    level,
    dismissible,
    spinner,
    translateParams
  }
});
const notificationShowDialog = (content, translateParams) => ({
  type: NOTIFICATION_SHOW,
  notification: {
    type: DIALOG_NOTIFICATION,
    content,
    translateParams
  }
});
const notificationShowToast = (content, level = INFO_NOTIFICATION, duration = 1e4, translateParams) => ({
  type: NOTIFICATION_SHOW,
  notification: {
    type: TOAST_NOTIFICATION,
    content,
    level,
    duration,
    translateParams
  }
});
const notificationHide = (type, content) => ({
  type: NOTIFICATION_HIDE,
  notification: { type, content }
});
export {
  BANNER_NOTIFICATION,
  DIALOG_NOTIFICATION,
  ERROR_NOTIFICATION,
  INFO_NOTIFICATION,
  NOTIFICATION_HIDE,
  NOTIFICATION_SHOW,
  OK_NOTIFICATION,
  TOAST_NOTIFICATION,
  WARNING_NOTIFICATION,
  notificationHide,
  notificationShowBanner,
  notificationShowDialog,
  notificationShowToast
};
