import { jsx, jsxs } from "react/jsx-runtime";
import { isEmpty, isString, set, uniq, uniqBy } from "lodash";
import { format, isAfter, isBefore, parseISO } from "date-fns";
import React, { Fragment } from "react";
import { useDeepCompareMemoize } from "../hooks";
const ipv4Regex = /^((0|1\d?\d?|2[0-4]?\d?|25[0-5]?|[3-9]\d?)\.){3}(0|1\d?\d?|2[0-4]?\d?|25[0-5]?|[3-9]\d?)$/;
const privateIpv4Regex = /^((10(\.(0|1\d?\d?|2[0-4]?\d?|25[0-5]|[3-9]\d?)){3})|(172\.(1[6-9]|2\d|3[01])(\.(0|1\d?\d?|2[0-4]?\d?|25[0-5]|[3-9]\d?)){2})|(192\.168(\.(0|1\d?\d?|2[0-4]?\d?|25[0-5]|[3-9]\d?)){2}))$/;
const ipv4CIDRRegex = /^(?:25[0-4]|2[0-4]\d|1\d\d|[1-9]\d?)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}\/(?:3[0-2]|[1-2]\d|[1-9])$/;
const privateIpv4CIDR10Regex = /^(10(\.(0|1\d?\d?|2[0-4]?\d?|25[0-5]|[3-9]\d?)){3})\/(?:3[0-2]|[1-2]\d|[8-9])$/;
const privateIpv4CIDR172Regex = /^(172\.(1[6-9]|2\d|3[01])(\.(0|1\d?\d?|2[0-4]?\d?|25[0-5]|[3-9]\d?)){2})\/(?:3[0-2]|2[0-9]|1[2-9])$/;
const privateIpv4CIDR192Regex = /^(192\.168(\.(0|1\d?\d?|2[0-4]?\d?|25[0-5]|[3-9]\d?)){2})\/(?:3[0-2]|2[0-9]|1[6-9])$/;
const numberRegex = /^(\d+)$/;
const emailRegex = /^[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
const domainRegex = /^((?=[a-zA-Z0-9-]{1,63}\.)(xn--)?[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,63}$/;
const wildcardDomainRegex = /^(([a-zA-Z0-9*]([*a-zA-Z0-9-]{0,61}[a-zA-Z0-9*])?)\.)+([a-zA-Z*]([*a-zA-Z0-9-]{0,61}[a-zA-Z0-9*])?)$/;
const validateRequired = (value, allValues, props) => (value || value === false || value === 0) && !(Array.isArray(value) && value.length === 0) ? void 0 : props.t("cuda.validation.required");
const validateArray = (validators, unique = false) => (value, allValues, props) => {
  const allValidators = Array.isArray(validators) ? validators : [validators];
  const error = [];
  value && value.some((arrayValue, index) => allValidators.some((validator) => {
    const validationResult = validator(arrayValue, allValues, props);
    validationResult && set(error, `[${index}]`, validationResult);
  }));
  if (error.length > 0) {
    return error;
  }
  if (unique && value) {
    const uniqueValues = uniqBy(value, (arrayValue) => typeof arrayValue === "string" ? arrayValue.toLowerCase() : arrayValue);
    if (uniqueValues.length !== value.length) {
      return props.t("cuda.validation.duplicate");
    }
  }
};
const validateArrayMemo = (validators, unique) => React.useMemo(() => validateArray(validators, unique), useDeepCompareMemoize([validators, unique]));
const validateRegex = (regex, errorMessage) => (value, allValues, props) => {
  if (!value || isString(value) && value.match(regex)) {
    return void 0;
  }
  return props.t(errorMessage);
};
const validateRegexMemo = (regex, errorMessage) => React.useMemo(() => validateRegex(regex, errorMessage), [regex.toString(), errorMessage]);
const validateNegativeRegex = (regex, errorMessage) => (value, allValues, props) => {
  if (!value || isString(value) && !value.match(regex)) {
    return void 0;
  }
  return props.t(errorMessage);
};
const validateNegativeRegexMemo = (regex, errorMessage) => React.useMemo(() => validateNegativeRegex(regex, errorMessage), [regex.toString(), errorMessage]);
const validateAlphaNumeric = validateRegex(/^[a-zA-Z0-9]+$/, "cuda.validation.alphaNumeric");
const validateIpv4 = validateRegex(ipv4Regex, "cuda.validation.ip");
const validatePrivateIpv4 = validateRegex(privateIpv4Regex, "cuda.validation.privateIp");
const validateCIDR = validateRegex(ipv4CIDRRegex, "cuda.validation.cidr");
const validatePrivate10CIDR = validateRegex(privateIpv4CIDR10Regex, "cuda.validation.privateIpCidr");
const validatePrivate172CIDR = validateRegex(privateIpv4CIDR172Regex, "cuda.validation.privateIpCidr");
const validatePrivate192CIDR = validateRegex(privateIpv4CIDR192Regex, "cuda.validation.privateIpCidr");
const validateIpv4CIDR = (value, allValues, props) => {
  if (validateIpv4(value, allValues, props) && validateCIDR(value, allValues, props)) {
    return props.t("cuda.validation.ipCidr");
  }
  return void 0;
};
const validatePrivateIpv4CIDR = (value, allValues, props) => {
  if (validatePrivate10CIDR(value, allValues, props) && validatePrivate172CIDR(value, allValues, props) && validatePrivate192CIDR(value, allValues, props)) {
    return props.t("cuda.validation.privateIpCidr");
  }
  return void 0;
};
const validateEmail = validateRegex(emailRegex, "cuda.validation.email");
const validateDomain = validateRegex(domainRegex, "cuda.validation.domain");
const validateWildcardDomain = validateRegex(wildcardDomainRegex, "cuda.validation.wildcardDomain");
const validateMinValue = (min) => (value, allValues, props) => {
  if (value && value < min) {
    return props.t("cuda.validation.valueMin", { min });
  }
};
const validateMinValueMemo = (min) => React.useMemo(() => validateMinValue(min), [min]);
const validateMaxValue = (max) => (value, allValues, props) => {
  if (value && value > max) {
    return props.t("cuda.validation.valueMax", { max });
  }
};
const validateMaxValueMemo = (max) => React.useMemo(() => validateMaxValue(max), [max]);
const validateInt = (value, allValues, props) => {
  if (!isEmpty(value) && typeof value === "string") {
    return validateRegex(numberRegex, "cuda.validation.int")(value, allValues, props);
  } else if (!isEmpty(value) && !Number.isInteger(Number(value))) {
    return props.t("cuda.validation.int");
  }
};
const validateMaxLength = (length) => (value, allValues, props) => {
  if (!isEmpty(value) && value.length > length) {
    return props.t("cuda.validation.lengthMax", { length });
  }
};
const validateMaxLengthMemo = (length) => React.useMemo(() => validateMaxLength(length), [length]);
const validateMinLength = (length) => (value, allValues, props) => {
  if (!isEmpty(value) && value.length < length) {
    return props.t("cuda.validation.lengthMin", { length });
  }
};
const validateMinLengthMemo = (length) => React.useMemo(() => validateMinLength(length), [length]);
const validateDateOnBefore = (latestDate) => (value, allValues, props) => {
  const compareDate = parseISO(value.toString());
  if (latestDate && isAfter(compareDate, latestDate)) {
    return props.t("cuda.validation.dateOnBefore", { date: format(latestDate, "yyyy-MM-dd HH:mm") });
  }
};
const validateDateOnBeforeMemo = (latestDate) => React.useMemo(() => validateDateOnBefore(latestDate), [latestDate]);
const validateDateOnAfter = (earliestDate) => (value, allValues, props) => {
  const compareDate = parseISO(value.toString());
  if (earliestDate && isBefore(compareDate, earliestDate)) {
    return props.t("cuda.validation.dateOnAfter", { date: format(earliestDate, "yyyy-MM-dd HH:mm") });
  }
};
const validateDateOnAfterMemo = (earliestDate) => React.useMemo(() => validateDateOnAfter(earliestDate), [earliestDate]);
const notIp = (value) => isString(value) ? !!value.match(/[^0-9./]/) : false;
const getRawError = (error) => {
  if (typeof (error == null ? void 0 : error.message) === "string") {
    try {
      return JSON.parse(error.message);
    } catch (err) {
      return error.message;
    }
  }
  return (error == null ? void 0 : error.message) || error;
};
const flatMapErrorMessage = (error) => {
  if (Array.isArray(error)) {
    return error.flatMap(flatMapErrorMessage).filter(Boolean);
  }
  if (typeof error === "object" && error !== null) {
    if (error == null ? void 0 : error.hasOwnProperty("message")) {
      const parsedError = getRawError(error);
      if (parsedError) {
        return flatMapErrorMessage(parsedError);
      }
    }
    const errorObject = typeof (error == null ? void 0 : error.types) === "object" ? error.types : error;
    return Object.values(errorObject).flatMap(flatMapErrorMessage).filter(Boolean);
  }
  return error || "";
};
const formatErrorMessage = (error) => {
  const flattenedError = flatMapErrorMessage(error);
  if (Array.isArray(flattenedError)) {
    return uniq(flattenedError).map((errorText, index) => /* @__PURE__ */ jsxs(Fragment, { children: [
      formatErrorMessage(errorText),
      index !== flattenedError.length - 1 ? /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx("br", {}) }) : null
    ] }, errorText));
  }
  return flattenedError || null;
};
export {
  domainRegex,
  emailRegex,
  formatErrorMessage,
  getRawError,
  ipv4CIDRRegex,
  ipv4Regex,
  notIp,
  numberRegex,
  privateIpv4CIDR10Regex,
  privateIpv4CIDR172Regex,
  privateIpv4CIDR192Regex,
  privateIpv4Regex,
  validateAlphaNumeric,
  validateArray,
  validateArrayMemo,
  validateCIDR,
  validateDateOnAfter,
  validateDateOnAfterMemo,
  validateDateOnBefore,
  validateDateOnBeforeMemo,
  validateDomain,
  validateEmail,
  validateInt,
  validateIpv4,
  validateIpv4CIDR,
  validateMaxLength,
  validateMaxLengthMemo,
  validateMaxValue,
  validateMaxValueMemo,
  validateMinLength,
  validateMinLengthMemo,
  validateMinValue,
  validateMinValueMemo,
  validateNegativeRegex,
  validateNegativeRegexMemo,
  validatePrivate10CIDR,
  validatePrivate172CIDR,
  validatePrivate192CIDR,
  validatePrivateIpv4,
  validatePrivateIpv4CIDR,
  validateRegex,
  validateRegexMemo,
  validateRequired,
  validateWildcardDomain,
  wildcardDomainRegex
};
