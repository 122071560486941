var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { debounce, merge, set } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useDeepCompareCallback, useDeepCompareEffect, usePrevious } from "./index";
import crudClient, { AUTHENTICATION_FAILED, CrudTypes } from "../clients/crudClient";
import { useGlobalParam } from "./GlobalParamsHooks";
import { authError, notificationShowDialog } from "../actions";
import { useI18next } from "../i18n";
const useCrudFetch = (type, url, params, options) => {
  const [globalParams] = useGlobalParam();
  const { activeLanguage } = useI18next();
  const [state, setState] = useState({
    data: void 0,
    loading: false,
    error: void 0
  });
  const dispatch = useDispatch();
  const abortController = useRef(new AbortController());
  const clearState = useCallback(() => setState({ data: void 0, loading: false, error: void 0 }), []);
  const performFetch = useDeepCompareCallback((overrideParams, overrideOptions, overrideUrl) => {
    const finalParams = merge({}, params, overrideParams);
    const finalOptions = merge({}, options, overrideOptions);
    const finalUrl = overrideUrl || url;
    abortController.current.abort();
    abortController.current = new AbortController();
    setState((currentState) => __spreadProps(__spreadValues({}, currentState), { loading: true }));
    return crudClient(
      type,
      finalUrl,
      finalParams,
      globalParams,
      {
        signal: abortController.current.signal,
        headers: {
          "Accept-Language": activeLanguage
        }
      }
    ).then((response) => {
      setState({ data: response.data, loading: false, error: void 0 });
      return { data: response.data };
    }).catch((error) => {
      var _a;
      if (error.name === "AbortError" || error.code === DOMException.ABORT_ERR) {
        return { data: void 0 };
      }
      const message = typeof error === "string" ? error : error && error.message;
      if (!finalOptions.quietErrors && error !== AUTHENTICATION_FAILED) {
        dispatch(notificationShowDialog(message));
      }
      dispatch(authError(error));
      const crudError = {
        statusCode: error == null ? void 0 : error.statusCode,
        body: error == null ? void 0 : error.body,
        message,
        error
      };
      setState({ error: crudError, loading: false, data: void 0 });
      if ((finalOptions == null ? void 0 : finalOptions.formPromise) && ((_a = error == null ? void 0 : error.body) == null ? void 0 : _a.errors)) {
        let violations = {};
        Object.keys(error.body.errors).sort().reverse().forEach((field) => {
          set(violations, field, error.body.errors[field]);
        });
        return Promise.reject(violations);
      }
      return { error: crudError };
    });
  }, [type, url, params, options, globalParams, activeLanguage]);
  useEffect(() => () => abortController.current.abort(), []);
  return [
    { data: state.data, error: state.error },
    state.loading,
    performFetch,
    clearState
  ];
};
const useCrudSubscription = (type, url, params, options) => {
  const { crudOptions, pollInterval = 6e4, debounceWait = 0 } = options || {};
  const [response, loading, performFetch, clearState] = useCrudFetch(type, url, params, crudOptions);
  const pollState = useRef({
    activeLoopId: 0,
    activeTimeoutId: 0,
    pollInterval,
    performFetch
  });
  pollState.current.pollInterval = pollInterval;
  pollState.current.performFetch = performFetch;
  const { renewLoop, startLoop, cleanUp } = useMemo(() => {
    const scheduleNextCall = (loopId) => {
      if (pollState.current.activeLoopId === loopId) {
        pollState.current.activeTimeoutId = window.setTimeout(
          () => debouncePerformCall == null ? void 0 : debouncePerformCall(loopId),
          pollState.current.pollInterval
        );
      }
    };
    const debouncePerformCall = debounce((loopId) => {
      var _a, _b;
      if (pollState.current.activeLoopId === loopId) {
        (_b = (_a = pollState.current).performFetch) == null ? void 0 : _b.call(_a).finally(() => scheduleNextCall(loopId));
      }
    }, debounceWait);
    const cleanUp2 = () => {
      pollState.current.activeLoopId++;
      pollState.current.activeTimeoutId && clearTimeout(pollState.current.activeTimeoutId);
      pollState.current.activeTimeoutId = 0;
    };
    const startLoop2 = () => {
      cleanUp2();
      debouncePerformCall(++pollState.current.activeLoopId);
    };
    const renewLoop2 = () => {
      cleanUp2();
      scheduleNextCall(++pollState.current.activeLoopId);
    };
    return { renewLoop: renewLoop2, cleanUp: cleanUp2, startLoop: startLoop2 };
  }, []);
  const oldPollInterval = usePrevious(pollInterval);
  const oldPerformFetch = usePrevious(performFetch);
  useDeepCompareEffect(() => {
    if (url && oldPollInterval && pollInterval > oldPollInterval && oldPerformFetch === performFetch) {
      renewLoop();
    } else if (url) {
      startLoop();
    }
    return () => cleanUp();
  }, [url, performFetch, pollInterval]);
  return [response, loading, startLoop, clearState];
};
const crudCache = {};
const useCrudProps = (url, params, options, cache = false, debounceWait = 0) => {
  const [response, loading, performFetch, clearState] = useCrudFetch(CrudTypes.GET, url, params, __spreadValues({ quietErrors: true }, options || {}));
  const performFetchRef = useRef(performFetch);
  performFetchRef.current = performFetch;
  const debouncePerformFetch = useCallback(debounce((params2, options2) => performFetchRef.current(params2, options2), debounceWait), [debounceWait]);
  let resolvedResponse = response;
  if (cache && url && !(resolvedResponse == null ? void 0 : resolvedResponse.data)) {
    try {
      resolvedResponse.data = crudCache[url] || void 0;
    } catch (error) {
      resolvedResponse.data = void 0;
    }
  }
  useDeepCompareEffect(() => {
    if (url && (!cache || !(resolvedResponse == null ? void 0 : resolvedResponse.data))) {
      debouncePerformFetch();
    }
  }, [url, params, options]);
  useEffect(() => {
    if ((resolvedResponse == null ? void 0 : resolvedResponse.data) && cache && url) {
      crudCache[url] = resolvedResponse.data;
    }
  }, [resolvedResponse == null ? void 0 : resolvedResponse.data]);
  return [resolvedResponse, loading, performFetch, clearState];
};
export {
  useCrudFetch,
  useCrudProps,
  useCrudSubscription
};
